import { useState, useRef, useEffect } from "react";
import "./Event.scss";
import ShareIcon from "@mui/icons-material/Share";
import CardModal from "../../../Component/CardModal/CardModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { ShareModal } from "../ShareModal/ShareModal";
import eventImage from "../../../asset/dynamicCard/eventImage.png";
import EventIcon from "@mui/icons-material/Event";
import EventWhiteIcon from "../../../asset/dynamicCard/event-white.png";
import { useSelector } from "react-redux";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";

export const Event = ({ inputs }) => {
  const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
    (state) => state.url
  );
  const containerRef = useRef();
  const [isShow, setIsShow] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState(false);
  const [popupOptions, setPopupOtions] = useState({
    title: "",
    description: "",
    icon: "",
    callback: "",
  });
  const [image, setImage] = useState("");
  useEffect(() => {
    setImage(inputs?.ownlogo);
  }, [inputs?.ownlogo]);
  const handleClose = () => {
    setShow(false);
    setState(false);
  };
  const SharePopup = () => {
    setState(true);
  };
  const ModalBody = (
    <>
      <div
        className="d-flex justify-content-center"
        style={{
          backgroundColor: "#5e55b9",
          padding: "inherit",
          width: "3em",
          height: "3em",
          borderRadius: "50%",
          color: "#ffff",
          margin: "auto",
        }}
      >
        <ShareModal state={state} />
      </div>
      <hr style={{ color: "grey" }} />
      <h3 className="pdfsharetxt">
        By tapping here, user can share the PDF to various social media
        platforms
      </h3>
      <h5 className="pdfsstxt" onClick={SharePopup}>
        Share
      </h5>
    </>
  );
  return (
    <div
      className="eventContainer"
      ref={containerRef}
      style={{ overflow: show || isShow || state ? "hidden" : "" }}
    >
      <div
        className="eventWrapper"
        style={{
          backgroundColor: dynamicColor?.color2 || inputs?.color || "#ffffff",
        }}
      >
        <div className="top">
          <div
            className="heading"
            style={{
              color:
                textColor?.text ||
                inputs?.colorPrimary ||
                inputs?.colorPrimary ||
                "#000000",
            }}
          >
            <span>
              {!inputs?.title ? "The Great Summer Party " : inputs?.title}
            </span>{" "}
            <span>
              <ShareIcon
                className="vertical-dot-icon"
                size="22"
                onClick={() => setShow(true)}
              />
              <CardModal show={show} onHide={handleClose} data={ModalBody} />
            </span>
          </div>
          <div
            className="desc"
            style={{
              color: textColor?.text || inputs?.colorPrimary || "#000000",
            }}
          >
            {!inputs?.summary
              ? "Come in, hand out and we'll make you loose all your worries"
              : inputs?.summary}
          </div>
        </div>
        <div className="imageCard">
          <div
            className="imageContainer"
            style={{
              backgroundColor:
                dynamicColor?.color2 || inputs?.color || "#ffffff",
            }}
          >
            <img  loading="lazy"  
              src={imgURL || image || inputs?.imageURL[0] || eventImage}
              alt="event"
            />
            <div className="button1 d-flex justify-content-center ">
              <button
                className="get-ticket-btn"
                style={{
                  backgroundColor:
                    dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
                  color: textColor?.text || inputs?.colorPrimary || "#fff",
                }}
                onClick={() => {
                  window.open(`https://${inputs?.button?.link}/`, "_blank");
                }}
              >
                {inputs?.button?.name || "Get Tickets"}
              </button>
            </div>
            <div
              className="PDFsidedesign"
              style={{
                backgroundColor:
                  dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
                right: 0,
              }}
            />
            <div
              className="PDFsidedesign"
              style={{
                backgroundColor:
                  dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
                left: 0,
                clipPath: "polygon(0px 0%, 100% 10%, 100% 100%, 0% 100%)",
              }}
            />
          </div>
        </div>
        <div
          className="card-main"
          style={{
            backgroundColor:
              dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
          }}
        >
          <div
            style={{
              zIndex: 999,
              fontSize: "small",
              textAlign: "left",
              marginTop: "1.3rem",
              border: "none",
              backgroundColor:
                dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
            }}
            className="card"
          >
            <div
              className="cardWrapper"
              style={{
                backgroundColor:
                  buttonColor?.button || inputs?.colorButton || "#fff",
              }}
            >
              <div className="item">
                <div className="icon">
                  <EventIcon />
                </div>
                <BusinessPopUP
                  show={isShow}
                  onHide={() => setIsShow(false)}
                  {...popupOptions}
                  ref={containerRef}
                />

                <div>
                  <div
                    className="heading"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    When
                  </div>
                  <div
                    className="detail"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "black",
                    }}
                  >
                    {inputs?.whenFrom || "Thu, 26 May 2022"}
                  </div>
                  <div
                    className="detail2"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    {inputs?.whenTo || "All day"}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <LocationOnIcon />
                </div>
                <div>
                  <div
                    className="heading"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    Where
                  </div>
                  <div
                    className="detail"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "black",
                    }}
                  >
                    {!inputs?.streetAddress &&
                    !inputs?.cityAddress &&
                    !inputs?.stateAddress &&
                    !inputs?.zipAddress &&
                    !inputs?.countryAddress
                      ? "Crp Hall,Connaught Place New Delhi"
                      : `${inputs?.streetAddress}, ${inputs?.cityAddress}-${inputs?.zipAddress}, ${inputs?.stateAddress}, ${inputs?.countryAddress}`}
                  </div>
                  <div
                    className="detail2"
                    onClick={() =>
                      window.open(
                        inputs?.streetAddress
                          ? `https://www.google.com/maps/place/${inputs?.streetAddress},${inputs?.cityAddress}-${inputs?.zipAddress},${inputs?.stateAddress},${inputs?.countryAddress}`
                          : "https://shrtco.de/TfHWiK",
                        "_blank"
                      )
                    }
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "black",

                      cursor: "pointer",
                    }}
                  >
                    Show on map
                  </div>
                  <div
                    className="eventAddicon"
                    style={{
                      backgroundColor:
                        dynamicColor?.color1 || inputs?.BGcolor || "#5e55b9",
                    }}
                  >
                    <img  loading="lazy"  
                      src={EventWhiteIcon}
                      onClick={() => {
                        setIsShow(true);
                        setPopupOtions({
                          iconComponent: (
                            <div className="share-div-popup">
                              <div className="share-social-facebook">
                                <EventIcon
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </div>
                            </div>
                          ),
                          submitButtonText: "Add",
                          description:
                            "By tapping here, user can add the event to their calenders.",
                        });
                      }}
                      alt="event"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <BookmarkIcon />
                </div>
                <div>
                  <div
                    className="heading"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    Facilities Available
                  </div>
                  <div className="featureIconContainer">
                    {inputs?.features?.map((feature) => (
                      <div className="featureIcon">
                        <i
                          className={`fas fa-${feature}`}
                          style={{
                            color:
                              textColor?.text || inputs?.colorPrimary || "gray",
                          }}
                        ></i>
                      </div>
                    ))}

                    {!inputs?.features && (
                      <>
                        <div className="featureIcon">
                          <i className="fas fa-wifi"></i>
                        </div>
                        <div className="featureIcon">
                          <i className="fas fa-wheelchair"></i>
                        </div>
                        <div className="featureIcon">
                          <i className="fas fa-utensils"></i>
                        </div>
                        <div className="featureIcon">
                          <i className="fas fa-cocktail"></i>
                        </div>
                        <div className="featureIcon">
                          <i className="fas fa-coffee"></i>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <InfoIcon />
                </div>
                <div>
                  <div
                    className="heading"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    About
                  </div>
                  <div
                    className="detail"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "black",
                    }}
                  >
                    {!inputs?.aboutUs
                      ? "Come in, hand out and we'll make you loose all your worries"
                      : inputs?.aboutUs}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <ContactPageIcon />
                </div>
                <div>
                  <div
                    className="heading"
                    style={{
                      color: textColor?.text || inputs?.colorPrimary || "gray",
                    }}
                  >
                    Contact
                  </div>
                  <div className="detailContainer">
                    <div className="detailItem">
                      <div
                        className="detail"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "black",
                        }}
                      >
                        {!inputs?.fullName ? "Amit Kumar" : inputs?.fullName}
                      </div>
                      <div
                        className="detail2"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "gray",
                        }}
                      >
                        Organiser
                      </div>
                    </div>
                    <div className="detailItem">
                      <div
                        className="detail"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "black",
                        }}
                      >
                        {!inputs?.mobileNumber
                          ? "+91-XXXXXXXXXX"
                          : inputs?.mobileNumber}
                      </div>
                      <div
                        className="detail2"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "gray",
                        }}
                      >
                        Phone
                      </div>
                    </div>
                    <div className="detailItem">
                      <div
                        className="detail"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "black",
                        }}
                      >
                        {!inputs?.email ? "test@gmail.com" : inputs?.email}
                      </div>
                      <div
                        className="detail2"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "gray",
                        }}
                      >
                        Email
                      </div>
                    </div>
                    <div className="detailItem">
                      <div
                        className="detail"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "black",
                        }}
                      >
                        {!inputs?.websiteLink
                          ? "https://www.google.com"
                          : inputs?.websiteLink}
                      </div>
                      <div
                        className="detail2"
                        style={{
                          color:
                            textColor?.text || inputs?.colorPrimary || "gray",
                        }}
                      >
                        Website
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
