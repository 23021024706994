import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown-simple";
import "./AdminSidebar.scss";
import { userRequest } from "../../requestMethod";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FolderItem } from "./FolderItem";
import { FolderName } from "../../redux/QrURL/qraction";
import { UserInfo } from "../../redux/exportAllAction";
const AdminSidebar = ({ admin, setSelect, select, difference }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allFolder, setAllFolder] = useState([]);
    const userState = useSelector((state) => state.user);
    const { currentUserId, userDetails } = userState;
    const [rename, setRename] = useState(false);
    const [drop, setDrop] = useState(false);
    const [input, setInput] = useState("");
    const [renameFol, setRenameFol] = useState("");
    const [active, setActive] = useState("default");
    const [access, setAccess] = useState([]);
    const [countdown, setCount] = useState("");

    useEffect(() => {
        if (userDetails) {
            setAccess(userDetails?.access);
            setAllFolder(userDetails?.folders);
            setCount(
                new Date(
                    userDetails?.expiryTimestamp
                        ? userDetails?.expiryTimestamp
                        : "01 January 2000 14:48 UTC"
                )?.toISOString()
            );
        }
    }, [userDetails]);
    const addFolder = async () => {
        const lastFolderNo = allFolder.length;

        try {
            if (lastFolderNo === 0) {
                await userRequest.post(`/user/folder/${currentUserId}`, {
                    folderName: "New Folder",
                });
                setAllFolder([...allFolder, "New Folder"]);
            } else {
                const FolderName = allFolder?.includes(
                    `New Folder ${lastFolderNo + 1}`
                )
                    ? `New Folder ${Math.floor(Math.random() * 100)}`
                    : `New Folder ${lastFolderNo + 1}`;
                await userRequest.post(`/user/folder/${currentUserId}`, {
                    folderName: FolderName,
                });
                setAllFolder([...allFolder, FolderName]);
            }
        } catch (error) {
            alert(error.response.data.message);
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    const deleteFolder = async (name) => {
        try {
            await userRequest.delete(`/user/folder/${currentUserId}`, {
                data: { folderName: name },
            });
            const a = allFolder.filter((f) => f !== name);
            setAllFolder(a);
            setDrop(false);
        } catch (error) {
            console.log(error);
        }
    };
    const userId = localStorage.getItem("userId");

    const renameFolder = async (e) => {
        try {
            if (e.key === "Enter" || e === "submit") {
                await userRequest.patch(
                    `/user/folder/${currentUserId || userId}`,
                    {
                        folderName: renameFol,
                        newFolderName: input,
                    }
                );
                setAllFolder(
                    allFolder.map((f) => {
                        if (f === renameFol) f = input;
                        return f;
                    })
                );
                setRename(false);
                setDrop(false);
                setRenameFol("");
                dispatch(UserInfo(currentUserId));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const filterAll = () => {
        setSelect("home");
        dispatch(FolderName("All"));
        navigate("/user/home");
        setActive("default");
    };

    function renderer({ days, hours, minutes }) {
        return (
        <div>
            <h6>{userDetails?.plan} Plan Expires in </h6>
            <p>
                {days ? days : "00"} days {hours ? hours : "00"} hrs{" "}
                {minutes ? minutes : "00"} min
            </p>
        </div>
    );
    } 

    return (
        <div className="adminSidebarContainer">
            <ToastContainer />
            <div className="adminSidebarWrapper">
                {admin ? (
                    <div className="adminSection">
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Dashboard")) && (
                            <div
                                className={
                                    select === "Dashboard"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Dashboard")}
                            >
                                <i className="fas fa-border-all"></i> Dashboard
                            </div>
                        )}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Users")) && (
                            <div
                                className={
                                    select === "Users"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Users")}
                            >
                                <i
                                    className="fas fa-store"
                                    style={{ marginLeft: "-2px" }}
                                ></i>{" "}
                                User Management
                            </div>
                        )}
                        {/* <div className={select === "customer" ? "option active " : "option"}  ><i className="fas fa-user"></i> Customer Management</div> */}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Invoice")) && (
                            <div
                                className={
                                    select === "Invoice"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Invoice")}
                            >
                                <i className="fas fa-file-invoice"></i> Invoice
                                Management
                            </div>
                        )}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Subscription")) && (
                            <div
                                className={
                                    select === "Subscription"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Subscription")}
                            >
                                <i className="fas fa-shopping-bag"></i>{" "}
                                Subscription Management
                            </div>
                        )}
                        {userState.loggedBy === "Admin" && (
                            <div
                                className={
                                    select === "subadmin"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("subadmin")}
                            >
                                <i className="fas fa-store"></i> SubAdmin
                                Management
                            </div>
                        )}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Blogs")) && (
                            <div
                                className={
                                    select === "Blogs"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Blogs")}
                            >
                                <i className="fab fa-blogger-b"></i> &nbsp;
                                &nbsp; All Blogs
                            </div>
                        )}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("QrCode")) && (
                            <div
                                className={
                                    select === "QrCode"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("QrCode")}
                            >
                                <i className="fas fa-qrcode"></i> QR Codes
                            </div>
                        )}
                        {(userState.loggedBy === "Admin" ||
                            access?.includes("Setting")) && (
                            <div
                                className={
                                    select === "Setting"
                                        ? "option active "
                                        : "option"
                                }
                                onClick={() => setSelect("Setting")}
                            >
                                <i className="fas fa-cog"></i> Settings
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="sections">
                        <div className="section1">
                            <div
                                className={
                                    active === "default"
                                        ? "item active"
                                        : "item"
                                }
                                onClick={filterAll}
                            >
                                <i className="fas fa-store"></i> My QR Codes
                            </div>
                        </div>
                        <div className="section2">
                            <div className="side-heading">My Folders</div>
                            {allFolder?.map((fol, key) => {
                                return (
                                    <div
                                        onClick={() => setActive(key)}
                                        onMouseLeave={() => setDrop(false)}
                                        className={
                                            active === key
                                                ? "d-flex active"
                                                : ""
                                        }
                                        key={"folder" + fol}
                                    >
                                        <FolderItem
                                            {...{
                                                fol,
                                                rename,
                                                renameFol,
                                                renameFolder,
                                                deleteFolder,
                                                setRename,
                                                setRenameFol,
                                                input,
                                                setInput,
                                            }}
                                        />
                                        {active === key && (
                                            <div className="position-relative">
                                                {!rename && (
                                                    <span
                                                        className="drop-handle"
                                                        title="drop-down"
                                                        onClick={() =>
                                                            drop
                                                                ? setDrop(false)
                                                                : setDrop(true)
                                                        }
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </span>
                                                )}
                                                {drop && !rename && (
                                                    <div className="folder-dropDown">
                                                        <div
                                                            onClick={() => {
                                                                setRename(true);
                                                                setRenameFol(
                                                                    fol
                                                                );
                                                            }}
                                                        >
                                                            Rename
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                deleteFolder(
                                                                    fol
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </div>

                                                        {/* <Dropdown
                         className="dropdown"
                        drop="down"
                        style={{
                          transform: "none !important",
                        }}
                      >
                        <Dropdown.Toggle>
                          <i className="fas fa-ellipsis-v mt-2" />
                        </Dropdown.Toggle>

                        
                      </Dropdown> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <div className="item">
                                <div
                                    className="text-newfolder"
                                    onClick={() => addFolder()}
                                >
                                    <i className="fas fa-plus text-newfolder"></i>{" "}
                                    New Folder
                                </div>
                            </div>
                        </div>

                        <div className="section3">
                            <div className="ncard">
                                <div className="more">
                                    {userDetails?.plan === "Free" &&
                                    difference <= 0 ? (
                                        "Free trial has been end"
                                    ) : (
                                        <Countdown
                                            targetDate={countdown}
                                            renderer={renderer}
                                        />
                                    )}
                                </div>
                                {userDetails?.plan !== "Premium" && (
                                    <button
                                        className={
                                            userDetails?.plan === "Free"
                                                ? "view"
                                                : "viewTwo"
                                        }
                                        onClick={() => navigate("/pricing")}
                                    >
                                        {userDetails?.plan === "Free"
                                            ? "Upgrade"
                                            : "Upgrade To Higher"}{" "}
                                        <i className="fas fa-crown" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminSidebar;
