import { useState } from "react";
import axios from "axios";
import Rate from "../../../Component/Rating/Rate";
import { AiOutlineRight } from "react-icons/ai";
export const CatagoriesItem = ({ item, inputs, isExpanded, setIsExpanded }) => {

    const sendExpandFeature = (e) => {
        setIsExpanded(true);
        setText(e.target.name);
    };
    const [rating, setRating] = useState(0);
    const [data, setData] = useState({
        name: "",
        email: "",
    });
    const inputHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const [text, setText] = useState("");

    const sendEmail = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/send-email`, {
            subject: `Rating from ${data.name}`,
            email: inputs?.email,
            template: `<p>Hello,  ${data.email}  Share feedback for ${text} . You got ${rating} star rating out of 5 </p>`,
        });
    };

    return (
        <>
            {!isExpanded ? (
                <div
                    className="data-form"
                    style={{ color: inputs?.colorPrimary || "#000000" }}
                >
                    <div
                        className="form-input"
                        name={item?.link}
                        style={{
                            color: inputs?.colorPrimary || "#000000",
                            backgroundColor: inputs?.button || "#ffffff",
                        }}
                        onClick={(e) => sendExpandFeature(e)}
                    >
                        <i className={`fas fa-${item?.icon} px-2`}></i>
                        {item?.link}
                        <AiOutlineRight
                            className="arrow-icon"
                            onClick={() => {
                                setIsExpanded(true);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <Rate
                            rating={rating}
                            onRating={(rate) => setRating(rate)}
                        />
                        <div>
                            <input
                                className="input"
                                type="email"
                                name="email"
                                style={{}}
                                onChange={(e) => inputHandler(e)}
                                placeholder="Please Enter Your Email"
                            />
                        </div>
                        <div>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                onChange={(e) => inputHandler(e)}
                                placeholder="Please Enter Your Name"
                            />
                        </div>
                        <div>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                onChange={(e) => inputHandler(e)}
                                placeholder="Please Enter Your Name"
                            />
                        </div>
                        <button className="mt-2" onClick={sendEmail}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
