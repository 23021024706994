import { useState, useEffect, useRef } from "react";
import { imageUrl } from "../../../../redux/QrURL/qraction";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebase/firebase";
import { useDispatch } from "react-redux";
import NoImage from "../../../../asset/no-image.jpg";
import "./uploadimage.css";
import Cropper from "react-easy-crop";
import { generateDownload } from "./CropImage";
import { TiDelete } from "react-icons/ti";
import { useLocation } from "react-router-dom";
const UploadImage = (props) => {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const [image, setImage] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [output, setOutput] = useState(null);
    const [preview, setPreview] = useState("");
    const location = useLocation();
    const triggerFileSelectPopup = (e) => {
        e.preventDefault();
        setOutput(null);
        setPreview(null);
        inputRef.current.click();
    };

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };
    const onSelectFile = (event) => {
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.addEventListener("load", () => {
                setImage(reader.result);
            });
        }
    };

    const onCroped = (e) => {
        e.preventDefault();
        generateDownload(image, croppedArea, setOutput);
    };
    useEffect(() => {
        uploadFiles();
    }, [output]);

    const uploadFiles = () => {
        if (!output) return;
        const storageRef = ref(storage, `files/${image.name + new Date()}`);
        const uploadTask = uploadBytesResumable(storageRef, output);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // const prog = `${Math.round(
                //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // )}%`;
                //   setProgress(prog);
            },
            (error) => console.log(error),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setPreview(downloadURL);
                    dispatch(imageUrl(downloadURL));

                    props?.setImg([...props?.img, downloadURL]);
                    setOutput("");
                });
            }
        );
    };

    const RemoveImage = () => {
        setPreview("");
        setImage(null);
        dispatch(imageUrl(""));
    };
    useEffect(() => {
        RemoveImage();
    }, [props?.img]);

    const circleCrop =
        location?.pathname?.slice(-12) === "vcardDynamic" ||
        location?.pathname?.slice(-15) === "facebookDynamic" ||
        location?.pathname?.slice(-10) === "appDynamic"
            ? { aspect: 4 / 4, cropShape: "round" }
            : location?.pathname?.slice(-12) === "pdfDynamic"
            ? { aspect: 111 / 124, cropShape: "square" }
            : { aspect: 16 / 9, cropShape: "square" };

    const cropSize =
        location?.pathname?.slice(-10) === "pdfDynamic"
            ? {
                  width: 222,
                  height: 248,
              }
            : location?.pathname?.slice(-13) === "couponDynamic"
            ? {
                  width: 335,
                  height: 374,
              }
            : "";

    return (
        <div className="box1">
            <div className="cropper-main">
                <div className="container-cropper">
                    {image && !preview ? (
                        <>
                            <div className="cropper">
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    cropSize={cropSize}
                                    aspect={circleCrop?.aspect}
                                    //  aspect={16 / 9}
                                    //aspect={4 / 4}
                                    //cropShape={"round"}
                                    cropShape={circleCrop?.cropShape}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />
                            </div>

                            <div className="crop-slider">
                                <input
                                    type="range"
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    onChange={(e) => setZoom(e.target.value)}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="cropped-image-preview">
                            <img  loading="lazy"  
                                src={preview || NoImage}
                                alt="crop"
                                width="100%"
                            />

                            {preview && (
                                <span title="remove">
                                    <TiDelete
                                        size="24"
                                        color="#ffffff"
                                        onClick={RemoveImage}
                                    />
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <div className="container-buttons">
                    <input
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={onSelectFile}
                        style={{ display: "none" }}
                    />
                    {image && !preview ? (
                        <>
                            <button
                                onClick={triggerFileSelectPopup}
                                style={{ marginRight: "10px" }}
                            >
                                Replace
                            </button>
                            <button
                                onClick={onCroped}
                                style={{ marginRight: "10px" }}
                            >
                                Crop
                            </button>
                            <button onClick={onCroped}>Save</button>
                        </>
                    ) : (
                        <button
                            onClick={triggerFileSelectPopup}
                            style={{ marginRight: "10px" }}
                        >
                            {preview ? "Replace Image" : "Upload Image"}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadImage;
