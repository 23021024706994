import ad1 from "../../asset/Ad/ad4.webp";
import ad2 from "../../asset/Ad/ad5.webp";
import ad3 from "../../asset/Ad/ad6.webp";
// import { Pagination } from "swiper";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ad.scss";
import "swiper/swiper.min.css";
import React from 'react';

export const Ad = () => {
  SwiperCore.use([Autoplay]);


  return (
    <>

      <div className="adContainer">

        <Swiper
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides={true}
          modules={[Pagination]}
          initialSlide={0}
          lazy={false}     // No lazy loading for first slide

        >
          <SwiperSlide>
            <img src={ad2} alt="ad1" width="60%" height="40%" 
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ad1} alt="ad2" width="60%" height="40%" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ad3} alt="ad3" width="60%" height="40%" loading="lazy" />
          </SwiperSlide>
        </Swiper>

      </div>
    </>
  );
};
