// make axios intance so that no need to pass hearder:authorization again and again

import axios from "axios";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logout } from "./redux/exportAllAction";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.REACT_APP_BACKEND_URL;
}
export const publicRequest = axios.create({ baseURL: BASE_URL });
export const userRequest = axios.create({ baseURL: BASE_URL });
export const adminRequest = axios.create({ baseURL: BASE_URL });



userRequest.interceptors.request.use(
  async (config) => {
    const ACCESS_TOKEN = localStorage.getItem("accessToken");
    config.headers.Authorization = "Bearer " + ACCESS_TOKEN;

    const decodedToken = await jwtDecode(ACCESS_TOKEN);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      useDispatch(logout());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// adminRequest.interceptors.request.use(
//   async (config) => {
//     const ACCESS_TOKEN = localStorage.getItem("accessToken");
//     config.headers["authorization"] = "Bearer " + ACCESS_TOKEN;

//     const decodedToken = await jwtDecode(ACCESS_TOKEN);
//     let currentDate = new Date();
//     if (decodedToken.exp * 1000 < currentDate.getTime()) {
//       useDispatch(logout());
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
