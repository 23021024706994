import { useRef, useState } from "react";
import { ContactPopupFeedback } from "../../../Component/ModalComponent/ModalComponent";
import Rate from "../../../Component/Rating/Rate";
import "./Feedback.css";
function ExpandedFeedback({
  title,
  buttonColor,
  textColor,
  inputs,
  setIsExpanded,
}) {
  const [rating, setRating] = useState(0);
  const [show, setShow] = useState(false);
  const [input, setInput] = useState({
    review: "",
    email: "",
    no: "",
    name: "",
  });
  const containerRef = useRef();
  return (
    <div className="expanded-feedback-cont" ref={containerRef}>
      <div className="expanded-feedback-title">{title}</div>
      <div className="expanded-feedback-subtitle">
        Please evaluate using the stars.
      </div>
      <div className="expanded-feedback-rating">
        <Rate rating={rating} onRating={(rate) => setRating(rate)} />
      </div>
      <div>
        <textarea
          type="textarea"
          placeholder="write your review"
          className="expanded-feedback-input"
          value={input.review}
          onChange={(e) => setInput({ ...input, review: e.target.value })}
        />
      </div>
      {/* <div>
        <button
          className="expanded-feedback-rate-more"
          onClick={() => setShow(true)}
          style={{ padding: "10px" }}
        >
          Save and Rate More
        </button>
      </div> */}
      <div>
        <button
          className="expanded-feedback-send-feedback"
          onClick={() => setShow(true)}
          style={{
            backgroundColor: buttonColor?.button || inputs?.button || "#fff",
            color: textColor?.text || inputs?.colorPrimary || "#fff",
            padding: "10px",
          }}
        >
          Send Feedback
        </button>
      </div>
      <ContactPopupFeedback
        show={show}
        onHide={() => {
          setShow(false);
          setIsExpanded(false);
        }}
        input={input}
        email={inputs?.email}
        rating={rating}
        setInput={setInput}
        title={title}
        ref={containerRef}
      />
    </div>
  );
}

export default ExpandedFeedback;
