import { useRef, useState, useEffect } from "react";
import cover from "../../../asset/dynamicCard/social-cover.png";
import profile from "../../../asset/dynamicCard/profile.svg";

import "./SocialMedia.css";
import { useSelector } from "react-redux";
import { ShareModal } from "../ShareModal/ShareModal";

export const SocialMedia = ({ inputs, detail }) => {
  const {
    dynamicColor,
    textColor,
    buttonColor,
    imgURL,
  } = useSelector((state) => state.url);
  const [image, setImage] = useState("");
  // const [isShow, setIsShow] = useState(false);
  const [state, setState] = useState(false);
  // const [popupOptions, setPopupOtions] = useState({
  //   description:
  //     "By tapping here, users are navigated to the linked social media profile or website.",
  // });
  const containerRef = useRef();
  useEffect(() => {
    setImage(inputs?.ownlogo);
  }, [inputs?.ownlogo]);

  const navigateCallback = (link) => {
    window.open(`${link}`, "_blank");
  };
  return (
    <div
      className="socialMediaContainer"
      style={{
        background: `linear-gradient(179.98deg, ${
          dynamicColor?.color1 || inputs?.BGcolor || "#ffffff"
        } 0.01%, ${dynamicColor?.color2 || inputs?.color || "#ffffff"} 99.99%)`,
        overflow: state ? "hidden" : "",
      }}
    >
      <div className="socialMediaWrapper" ref={containerRef}>
        <div className="content-overflow mb-5" style={{ marginBottom: "3em" }}>
          <div className="content-image">
            <div className="social-image-mask"></div>
            <img  loading="lazy"  
              src={imgURL || inputs?.imageURL[0] || image || cover}
              className="social-cover"
              alt=""
            />
          </div>
          <div className="social-upper-content">
            <div>
              <img  loading="lazy"  
                src={inputs?.ownlogo ? inputs?.ownlogo : profile}
                className="social-profile"
                 alt=""
              />
            </div>
            <div
              className="social-text-1"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
              }}
            >
              {!inputs?.headline
                ? "Follow us on Social Media"
                : inputs?.headline}
            </div>
            <div
              className="social-text-2"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
              }}
            >
              {!inputs?.aboutUs
                ? "Follow us and get updates delivered to your favorite social media Channel."
                : inputs?.aboutUs}
            </div>
          </div>
        </div>

        {inputs?.socialLinks?.length === 0 ? (
          <div
            className="social-bottom-content"
            // onClick={() => setIsShow(true)}
          >
            <div
              className="bottom-tab-facebook"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
                backgroundColor: buttonColor?.button || "#3b5996",
              }}
            >
              <i className="fab fa-facebook socialIcon-size" />
              <div className="social-text"> Like Us On Facebook</div>
            </div>
            <div
              className="bottom-tab-twitter"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
                backgroundColor: buttonColor?.button || "#0283bd",
              }}
            >
              <i class="fab fa-twitter socialIcon-size" />
              <div className="social-text"> FOLLOW Us on TWITTER</div>
            </div>
            <div
              className="bottom-tab-instagram"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
                backgroundColor: buttonColor?.button || "#d34e63",
              }}
            >
              <i class="fab fa-instagram socialIcon-size" />
              <div className="social-text">FOLLOW Us on INSTAGRAM</div>
            </div>
            <div
              className="bottom-tab-linkedin"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
                backgroundColor: buttonColor?.button || "#3375b1",
              }}
            >
              <i class="fab fa-linkedin socialIcon-size" />
              <div className="social-text">CONNECT WITH Us on LINKEDIN</div>
            </div>
            <div
              className="bottom-tab-youtube"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
                backgroundColor: buttonColor?.button || "#bd342c",
              }}
            >
              <i class="fab fa-youtube socialIcon-size" />
              <div className="social-text mx-2">
                SUBSCRIBE OUR YOUTUBE CHANNEL
              </div>
            </div>
          </div>
        ) : !inputs?.socialLinks ? (
          inputs?.socialLinks?.map((item) => (
            <div
              className="social-bottom-content"
              // onClick={() => setIsShow(true)}
            >
              <div
                className={`bottom-tab-${item.name} `}
                // onClick={() => {
                //   setIsShow(true);
                //   const data = {
                //     iconComponent: (
                //       <i
                //         className={`fab fa-${item.name} fa-2`}
                //         style={{ fontSize: "20px" }}
                //       />
                //     ),
                //     callback: () => navigateCallback(item.url),
                //     title:
                //       item.name === "youtube"
                //         ? "Subscribe our channel on Youtube"
                //         : item.name === "linkedin"
                //         ? "Connect with us on Linkedln "
                //         : item.name === "facebook"
                //         ? "Like us "
                //         : item.name === "instagram"
                //         ? "Follow us on Instagram"
                //         : item.name === "twitter"
                //         ? "Follow us on Twitter"
                //         : item.name,
                //   };
                //   // setPopupOtions((prev) => {
                //   //   return { ...prev, ...data };
                //   // });
                // }}
                style={{
                  color: textColor?.text || inputs?.colorPrimary || "#fff",
                  backgroundColor: buttonColor?.button || "",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.name === "tiktok" && (
                  <>
                    <i className="fab fa-tiktok socialIcon-size" />{" "}
                  </>
                )}
                {item.name === "telegram" && (
                  <i className="fab fa-telegram socialIcon-size" />
                )}
                {item.name === "yelp" && (
                  <i className="fab fa-yelp socialIcon-size" />
                )}
                {item.name === "google" && (
                  <i className="fab fa-google socialIcon-size" />
                )}
                {item.name === "github" && (
                  <i className="fab fa-github socialIcon-size" />
                )}
                {item.name === "spotify" && (
                  <i className="fab fa-spotify socialIcon-size" />
                )}
                {item.name === "pinterest" && (
                  <i className="fab fa-pinterest socialIcon-size" />
                )}
                {item.name === "xing" && (
                  <i className="fab fa-xing socialIcon-size" />
                )}
                {item.name === "vimeo" && (
                  <i class="fab fa-vimeo-v socialIcon-size"></i>
                )}
                {item.name === "reddit" && (
                  <i className="fab fa-reddit socialIcon-size" />
                )}
                {item.name === "applemusic" && (
                  <i className="fab fa-applemusic socialIcon-size" />
                )}
                {item.name === "snapchat" && (
                  <i className="fab fa-snapchat socialIcon-size" />
                )}
                {item.name === "facebook" && (
                  <i className="fab fa-facebook socialIcon-size" />
                )}
                {item.name === "instagram" && (
                  <i class="fab fa-instagram socialIcon-size" />
                )}
                {item.name === "twitter" && (
                  <i class="fab fa-twitter socialIcon-size" />
                )}
                {item.name === "whatsapp" && (
                  <i class="fab fa-whatsapp socialIcon-size" />
                )}
                {item.name === "linkedin" && (
                  <i class="fab fa-linkedin socialIcon-size" />
                )}
                {item.name === "youtube" && (
                  <i class="fab fa-youtube socialIcon-size" />
                )}
                <div className="social-text">
                  {item.name === "youtube" ? "" : ""}{" "}
                  {item.name === "linkedin" ? " " : ""}{" "}
                  {item.name === "facebook" ? "" : ""}
                  {
                    <div
                      className="d-flex flex-column align-items-flex-start ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateCallback(item.url)}
                    >
                      <h6
                        style={{
                          marginBottom: "2px",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {item?.name?.toUpperCase()}
                      </h6>{" "}
                      <p style={{ marginBottom: "2px", fontSize: "10px" }}>
                        {item?.url?.toLowerCase().substr(0, 55)}
                      </p>{" "}
                    </div>
                  }
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className="social-bottom-content"
            // onClick={() => setIsShow(true)}
          >
            {inputs?.socialLinks.map((item) => (
              <div
                className={`bottom-tab-${item.name} `}
                // onClick={() => {
                  // setIsShow(true);
                  // const data = {
                  //   iconComponent: (
                  //     <i className={`fab fa-${item.name} socialIcon-size `} />
                  //   ),

                  //   title:
                  //     item.name === "youtube"
                  //       ? "Subscribe our channel on Youtube"
                  //       : item.name === "linkedin"
                  //       ? "Connect with us on Linkedln "
                  //       : item.name === "facebook"
                  //       ? "Like us on Facebook"
                  //       : item.name === "instagram"
                  //       ? "Follow us on Instagram"
                  //       : item.name === "twitter"
                  //       ? "Follow us on Twitter"
                  //       : item.name,
                  // };
                  // setPopupOtions((prev) => {
                  //   return { ...prev, ...data };
                  // });
                // }}
                style={{
                  color: textColor?.text || inputs?.colorPrimary || "#fff",
                  backgroundColor: buttonColor?.button || "",
                }}
              >
                {item.name === "tiktok" && (
                  <i className="fab fa-tiktok socialIcon-size" />
                )}
                {item.name === "telegram" && (
                  <i className="fab fa-telegram socialIcon-size" />
                )}
                {item.name === "yelp" && (
                  <i className="fab fa-yelp socialIcon-size" />
                )}
                {item.name === "google" && (
                  <i className="fab fa-google socialIcon-size" />
                )}
                {item.name === "github" && (
                  <i className="fab fa-github socialIcon-size" />
                )}
                {item.name === "spotify" && (
                  <i className="fab fa-spotify socialIcon-size" />
                )}
                {item.name === "pinterest" && (
                  <i className="fab fa-pinterest socialIcon-size" />
                )}
                {item.name === "xing" && (
                  <i className="fab fa-xing socialIcon-size" />
                )}
                {item.name === "vimeo" && (
                  <i className="fab fa-vimeo-v socialIcon-size" />
                )}
                {item.name === "reddit" && (
                  <i className="fab fa-reddit socialIcon-size" />
                )}
                {item.name === "applemusic" && (
                  <i className="fab fa-applemusic socialIcon-size" />
                )}
                {item.name === "snapchat" && (
                  <i className="fab fa-snapchat socialIcon-size" />
                )}
                <div className="social-text">
                  {item.name === "facebook" && (
                    <i className="fab fa-facebook socialIcon-size" />
                  )}
                  {item.name === "instagram" && (
                    <i class="fab fa-instagram socialIcon-size" />
                  )}
                  {item.name === "twitter" && (
                    <i class="fab fa-twitter socialIcon-size" />
                  )}
                  {item.name === "whatsapp" && (
                    <i class="fab fa-whatsapp socialIcon-size" />
                  )}
                  {item.name === "linkedin" && (
                    <i
                      class="fab fa-linkedin socialIcon-size"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {item.name === "youtube" && (
                    <i
                      class="fab fa-youtube socialIcon-size"
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {item.name === "youtube" ? "SUBSCRIBE OUR" : ""}{" "}
                  {item.name === "linkedin" ? "CONNECT TO " : ""}{" "}
                  {item.name === "facebook" ? "LIKE US " : ""}{" "}
                  {item?.name?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="share-div py-3">
          <div
            className="share-social"
            onClick={() => setState(true)}
            // onClick={() => {
            //   setIsShow(true);
            //   setPopupOtions({
            //     iconComponent: (
            //       <div className="share-div-popup">
            //         <div className="share-social">
            //         <ShareModal state={state} />
            //         </div>
            //       </div>
            //     ),
            //     title: "",
            //     description:
            //       "Your contacts can click this button to share your page.",
            //   });
            // }}
          >
            <ShareModal state={state} />
          </div>
        </div>
      </div>
      {/* <BusinessPopUP
        show={isShow}
        onHide={() => setIsShow(false)}
        {...popupOptions}
        ref={containerRef}
      /> */}
    </div>
  );
};
