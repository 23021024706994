import { useRef, useState, useEffect } from "react";
import profile from "../../../asset/dynamicCard/profile.svg";
import { ShareModal } from "../ShareModal/ShareModal";
import "./Facebook.css";
import fb from "./fb.png";
import { useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";
import FacebookIcon from "@mui/icons-material/Facebook";
export const Facebook = ({ inputs }) => {
    const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
        (state) => state.url
    );
    const [image, setImage] = useState("");
    const [share, setShare] = useState(false);
    // modal
    const containerRef = useRef();
    const [isShow, setIsShow] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        title: "",
        description: "",
        icon: "",
        callback: "",
        submitButtonText: "",
    });
    useEffect(() => {
        setImage(inputs?.ownlogo);
    }, [inputs?.ownlogo]);

    // sharemodal
    const handleToggle = () => {
        console.log("hello");
    };


    return (
        <div className="facebookContainer" ref={containerRef}>
            <div className="facebookWrapper">
                {/* <ShareModal /> */}

                <div className="share-facebook-div ">
                    <BusinessPopUP
                        show={isShow}
                        onHide={() => setIsShow(false)}
                        {...popupOptions}
                        ref={containerRef}
                    />
                    <ShareIcon
                        style={{
                            color:
                                buttonColor?.button ||
                                inputs?.button ||
                                "#ffffff",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setIsShow(true);
                            handleToggle();
                            setPopupOtions({
                                iconComponent: (
                                    <div className="share-div-popup">
                                        <div
                                            className="share-social-facebook"
                                            onClick={() => setShare(true)}
                                        >
                                            <ShareModal state={share} />
                                        </div>
                                    </div>
                                ),
                                submitButtonText: "Share Page",
                                description:
                                    "By tapping here, user can share the facebook page to various platforms",
                            });
                        }}
                    />
                </div>
                <img  loading="lazy"  
                    src={fb}
                    style={{ width: "inherit", fontSize: "2.2rem" }}
                />

                <div
                    className="main-content-facebook"
                    style={{
                        background: `linear-gradient(179.98deg, ${
                            dynamicColor?.color2 || inputs?.color || "#4c689f"
                        } 0.01%, ${
                            dynamicColor?.color1 || inputs?.BGcolor || "#4c689f"
                        } 28.99%)`,
                    }}
                >
                    <div className="facebook-logo">
                        <img  loading="lazy"  
                            src={
                                (inputs?.imageURL.length > 0 &&
                                    inputs?.imageURL[0]) ||
                                imgURL ||
                                image ||
                                profile
                            }
                            alt="cover"
                            className="profile-logo-facebook"
                        />
                    </div>
                    <div className="facebook-card">
                        <p
                            className="facebook-card-title"
                            style={{
                                color: textColor?.text || "#fff",
                            }}
                        >
                            {!inputs?.name ? "Facebook" : inputs?.name}
                        </p>
                        <p
                            className="facebook-card-content"
                            style={{
                                color: textColor?.text || "#fff",
                            }}
                        >
                            {!inputs?.title
                                ? "Click on the like button to follow us on Facebook."
                                : inputs?.title}
                        </p>
                    </div>
                    <div className="facebook-bottom-button">
                        {inputs?.facebookURL?.length > 0 ? (
                            <iframe
                                src={`https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F${
                                    inputs?.facebookURL.split("/")[
                                        inputs?.facebookURL.split("/").length -
                                            1
                                    ]
                                }&width=460&layout=button_count&action=like&size=large&share=false&height=21&appId`}
                                width="460"
                                height="30"
                                style={{
                                    border: "none",
                                    overflow: "hidden",
                                    width: "40%",
                                }}
                                scrolling="no"
                                frameBorder="0"
                                allowFullScreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe>
                        ) : (
                            <iframe
                                src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fkyloapps&width=460&layout=button_count&action=like&size=large&share=false&height=21&appId"
                                width="460"
                                height="30"
                                style={{
                                    border: "none",
                                    overflow: "hidden",
                                    width: "40%",
                                }}
                                scrolling="no"
                                frameBorder="0"
                                allowFullScreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe>
                        )}
                        {/* </a>
            </div> */}
                    </div>
                    {window.location.pathname.includes("/facebookDynamic") ? (
                        <div
                            style={{
                                backgroundColor:
                                    buttonColor?.button || "#ffffff",
                            }}
                            className="facebook-goto"
                            onClick={() => {
                                setIsShow(true);
                                handleToggle();
                                setPopupOtions({
                                    iconComponent: (
                                        <div className="share-div-popup">
                                            <div className="share-social-facebook">
                                                <FacebookIcon />
                                            </div>
                                        </div>
                                    ),
                                    submitButtonText: "Go to the page",
                                    description:
                                        "By tapping here, user will be redirected to your facebook page",
                                });
                            }}
                        >
                            <p
                                style={{
                                    color: textColor
                                        ? textColor?.text
                                        : "#000000",
                                }}
                            >
                                Go to our Facebook Page
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
