import { useState } from "react";
import cover from "../../../asset/dynamicCard/cover.png";
import "./Coupon.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ShareModal } from "../ShareModal/ShareModal";
import { CouponModal } from "../CouponModal/CouponModal";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import CardModal from "../../../Component/CardModal/CardModal";

const Coupon = ({ inputs, qrInfo }) => {
    const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
        (state) => state.url
    );
    const [show, setShow] = useState(false);
    const [redeem, setRedeem] = useState(false);
    const ModalBody = (
        <>
            <div className="my-2 text-align-center">
                <Button
                    className="bottom-Cbtn"
                    style={{
                        marginBottom: "20px",
                        backgroundColor:
                            buttonColor?.button || inputs?.button || "#388aa2",
                        color:
                            textColor?.text ||
                            inputs?.colorPrimary ||
                            "#ffffff",
                    }}
                    onClick={() => setRedeem(false)}
                >
                    {inputs?.webText}
                </Button>
                <p
                    className="px-2"
                    style={{
                        color: "rgba(0, 0, 0, 0.40)",
                        fontWeight: "600",
                    }}
                >
                    {!inputs?.description
                        ? " Name this optional button as you want and link to any website of your choice"
                        : inputs?.description}
                </p>
                <p
                    style={{
                        color:
                            textColor?.text ||
                            inputs?.colorPrimary ||
                            "#02AAF5",
                        fontWeight: "600",
                    }}
                    onClick={() => setRedeem(false)}
                >
                    Got it
                </p>
            </div>
        </>
    );

    const handleDate = (date) => {
        const inputDate = new Date(date);
        const options = { day: "numeric", month: "long", year: "numeric" };
        const outputDate = inputDate.toLocaleDateString("en-US", options);
        return outputDate;
    };

    return (
        <div className="couponContainer">
            <div
                className="couponWrapper "
                style={{
                    position: "relative",
                    backgroundColor: show
                        ? "rgb(0 0 0 / 50%)"
                        : dynamicColor?.color2 || inputs?.color || "#ffffff",
                    paddingBottom: "35px",
                }}
            >
                <div
                    className="imageContainer"
                    style={{ opacity: show ? 0.4 : 1 }}
                >
                    <img  loading="lazy"  
                        src={
                            qrInfo?.imageURL[0] ||
                            imgURL ||
                            inputs?.imageURL[0] ||
                            cover
                        }
                        alt="cover-img"
                    />
                </div>
                <div
                    style={{
                        backgroundColor:
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#388aa2",
                    }}
                    className="card"
                >
                    <div
                        className="cardTop"
                        style={{
                            opacity: show ? -1 : 1,
                            borderColor:
                                dynamicColor?.color2 ||
                                inputs?.color ||
                                "#ffffff",
                        }}
                    >
                        <div
                            className="heading"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#fff",
                            }}
                        >
                            {!inputs?.company
                                ? qrInfo?.company
                                    ? qrInfo?.company
                                    : "Dharma Clothes"
                                : inputs?.company}
                        </div>
                        <div className="Cbutton w-100">
                            <button
                                style={{
                                    backgroundColor:
                                        buttonColor?.button ||
                                        inputs?.button ||
                                        "#fff",
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#388aa2",
                                }}
                            >
                                Flat {qrInfo?.saleBadge ?? inputs?.saleBadge}{" "}
                                off
                            </button>
                        </div>
                        <div className="detail">
                            <div
                                className="title"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#fff",
                                }}
                            >
                                {!inputs?.headline
                                    ? qrInfo?.headline
                                        ? qrInfo?.headline
                                        : "Fashion Sale"
                                    : inputs?.headline}
                            </div>
                            <div
                                className="desc"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#fff",
                                }}
                            >
                                {!inputs?.description
                                    ? qrInfo?.description
                                        ? qrInfo?.description
                                        : "Sale upto 50% on all Clothes Brands"
                                    : inputs?.description}
                            </div>
                        </div>
                        <span
                            className="ellipse-border"
                            style={{
                                background:
                                    dynamicColor?.color2 ||
                                    inputs?.color ||
                                    "#ffffff",
                            }}
                        />
                        <span
                            className="ellipse-border"
                            style={{
                                right: "-10px",
                                left: "auto",
                                background:
                                    dynamicColor?.color2 ||
                                    inputs?.color ||
                                    "#ffffff",
                            }}
                        />
                    </div>

                    <div className="cardBottom">
                        <div
                            className="Cbutton mt-2 w-100"
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#fff",
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#388aa2",
                            }}
                        >
                            <CouponModal
                                setRedeem={setRedeem}
                                input={inputs}
                                sale={
                                    !inputs?.saleBadge
                                        ? qrInfo?.saleBadge
                                            ? qrInfo?.saleBadge
                                            : "50%"
                                        : inputs?.saleBadge
                                }
                                valid={
                                    !inputs?.couponValidUntil
                                        ? qrInfo?.couponValidUntil
                                            ? handleDate(
                                                  qrInfo?.couponValidUntil
                                              )
                                            : "31 May 2022"
                                        : inputs?.couponValidUntil
                                }
                                show={show}
                                setShow={setShow}
                            />
                        </div>
                    </div>
                </div>

                <CardModal
                    show={redeem}
                    onHide={() => setRedeem(false)}
                    data={ModalBody}
                    closeIcon={false}
                />
                <div className="contactContainer d-grid mt-3">
                    <div
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#ffffff",
                        }}
                    >
                        <div className="d-flex justify-content-start text-start align-items-center">
                            <span className="icon ">
                                <LocationOnIcon
                                    style={{
                                        color:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#000000",
                                    }}
                                />
                            </span>{" "}
                            &nbsp;
                            <span
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#000000",
                                }}
                            >
                                Location
                            </span>
                        </div>
                        <div
                            className="detail text-start"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            {!inputs?.streetAddress ? (
                                qrInfo?.streetAddress ? (
                                    qrInfo?.streetAddress
                                ) : (
                                    <>
                                        4 Jagmohan mullick lane,
                                        <br /> New Market,kolkata
                                    </>
                                )
                            ) : (
                                inputs?.streetAddress
                            )}
                            <a
                                href={`https://www.google.com/maps/place/${
                                    !inputs?.streetAddress
                                        ? qrInfo?.streetAddress
                                            ? qrInfo?.streetAddress
                                            : "4 Jagmohan mullick lane, New Market,kolkata"
                                        : inputs?.streetAddress
                                }`}
                            >
                                <div
                                    className="blue-text"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                    }}
                                >
                                    Show in map
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div
                        className="circle"
                        style={{
                            backgroundColor:
                                dynamicColor?.color1 ||
                                inputs?.BGcolor ||
                                "#388aa2",
                        }}
                    >
                        <ShareModal />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Coupon;
