import { useRef, useState } from "react";
import cover from "../../../asset/dynamicCard/RatingCover.png";
import like_icon from "../../../asset/like.svg";
import "./Rating.css";
import { useSelector } from "react-redux";
import Rate from "../../../Component/Rating/Rate";
import Like from "../../../Component/Rating/Like";
import Emoji from "../../../Component/Rating/Emoji";
import axios from "axios";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";

export const Rating = ({ inputs, ratingType = "star" }) => {
    const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
        (state) => state.url
    );
    const containerRef = useRef();
    const [isShow, setIsShow] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        title: "",
        description: "",
        icon: "",
        callback: "",
    });
    const [rating, setRating] = useState(0);
    const [like, setLike] = useState("");
    const [comment, setComment] = useState("");
    const [emoji, setEmoji] = useState("");
    let rates =
        ratingType === "star" ? rating : ratingType === "thumb" ? like : emoji;
    const EmailBody = {
        email: inputs?.email,
        type: "Rating",
        name: inputs?.qrName,
        question: inputs?.question,
        rating: rates,
        comment,
    };

    const SendRating = () => {
        if (inputs?.email) {
            axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/send-email`,
                EmailBody
            );

            setIsShow(true);
            setPopupOtions({
                title: "",
                description: "Your Response Has Been Successfully Submitted.",
                icon: like_icon,
                submitButtonText: "Close Tab",
            });
        } else {
            alert("Please input email to send rating");
        }
    };

   

   
    return (
        <div
            className="ratingContainer"
            style={{
                backgroundColor:
                    dynamicColor?.color2 || inputs?.color || "#fff",
            }}
            ref={containerRef}
        >
            <div className="ratingWrapper">
                <div
                    className="upper-content-rating"
                    style={{
                        color:
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#407ec1",
                    }}
                >
                    <p
                        className="brand-name-rating"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#000000",
                        }}
                    >
                        {!inputs?.company ? "The Sea Bunglow" : inputs?.company}
                    </p>
                    <p
                        className="brand-desc-rating"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#000000",
                        }}
                    >
                        {!inputs?.description
                            ? "Your feedback really matters to us Please rate your overall rental experience"
                            : inputs?.description}
                    </p>
                </div>
                <div className="image-content-rating">
                    <img  loading="lazy"  
                        className="pdf-image-cover-rating"
                        src={imgURL || inputs?.imageURL[0] || cover}
                    />
                    <div className="image-text-menu-rating">
                        <h5 className="mb-4">
                            {inputs?.question || "How was your experience ?"}
                        </h5>
                        <div className="rating-icon-section">
                            {ratingType === "thumb" && (
                                <Like
                                    like={like}
                                    onLike={(like) => setLike(like)}
                                />
                            )}
                            {ratingType === "emoji" && (
                                <Emoji
                                    emoji={emoji}
                                    onRate={(emoji) => setEmoji(emoji)}
                                />
                            )}
                            {ratingType === "star" && (
                                <Rate
                                    rating={rating}
                                    onRating={(rate) => setRating(rate)}
                                    style={{ cursor: "pointer" }}
                                />
                            )}

                            
                        </div>
                        {rating !== 0 ||
                        like.length !== 0 ||
                        emoji.length !== 0 ? (
                            <div className="rating-comment-cont">
                                <textarea
                                    className="rating-comment px-2 py-3"
                                    placeholder="Add a comment(optional)"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div
                        style={{ width: "100%", display: "flex" }}
                        className="bg-degisgn"
                    >
                        <div
                            style={{
                                flex: 1,
                                borderBottomColor: dynamicColor?.color1
                                    ? dynamicColor?.color1
                                    : "#407ec1",
                            }}
                            className="bg-design-1"
                        ></div>
                        <div
                            style={{
                                flex: 1,
                                borderBottomColor: dynamicColor?.color1
                                    ? dynamicColor?.color1
                                    : "#407ec1",
                            }}
                            className="bg-design-2"
                        ></div>
                    </div>
                </div>
                <div>
                    
                </div>
                <div
                    className="bottom-content-rating"
                    style={{
                        backgroundColor: dynamicColor?.color1 || "#407ec1",
                        paddingTop:
                            rating || like.length > 0 || emoji.length > 0
                                ? "11rem"
                                : 0,
                        paddingBottom: rating !== 0 ? "" : "8rem",
                    }}
                >
                    <div>
                        <div className="brand-rating">
                            <a
                                className="brand-link-rating"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#fff",
                                }}
                                href={`https://${
                                    !inputs?.websiteLink
                                        ? "WWW.SouthernRestaurant.com"
                                        : inputs?.websiteLink
                                }`}
                            >
                                {!inputs?.websiteLink
                                    ? "WWW.SouthernRestaurant.com"
                                    : inputs?.websiteLink}
                            </a>
                        </div>
                        <button
                            className="bottom-content-button-rating"
                            style={{
                                backgroundColor: buttonColor?.button || "#fff",
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#407ec1",
                                borderRadius: "10px",
                            }}
                            onClick={() => {
                                SendRating();
                            }}
                        >
                            Send Ratings
                        </button>
                        <BusinessPopUP
                            show={isShow}
                            onHide={() => setIsShow(false)}
                            {...popupOptions}
                            ref={containerRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
