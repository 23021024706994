import {
    LOGIN_BY,
    LOGIN_FAIL,
    LOGIN_START,
    LOGIN_SUCCESS,
    USER_INFO,
} from "./userType";
import "react-toastify/dist/ReactToastify.css";
import { publicRequest } from "../../requestMethod";
import { toast } from "react-toastify";
export const loginStart = () => ({
    type: LOGIN_START,
});
export const loginSuccess = (userId) => ({
    type: LOGIN_SUCCESS,
    payload: userId,
});
export const loginUserData = (data) => ({
    type: USER_INFO,
    payload: data,
});
export const loggedBy = (role) => ({
    type: LOGIN_BY,
    payload: role,
});

export const loginFail = (error) => ({
    type: LOGIN_FAIL,
    payload: error,
});
export const clearError = () => ({
    type: "CLEAR_ERROR",
    payload: "",
});

export const login = (userDetail, type, setInputOtp) => {
    return async (dispatch) => {
        await dispatch(loginStart());
        try {
            const res =
                type === "basic"
                    ? await publicRequest.post("/user/login", userDetail)
                    : await publicRequest.post("/user/sociallogin", {
                          firebaseId: userDetail,
                      });

            const user = res.data;
            setInputOtp({ id: user.id, isTrue: false });
            localStorage.setItem("accessToken", user?.token);
            localStorage.setItem("userId", user?.id);
            await dispatch(loginSuccess(user?.id));
            await dispatch(loggedBy(user?.role));
            await dispatch(loginUserData(user?.user));
        } catch (error) {
            if (error?.response?.data?.message === "User unverified!") {
                setInputOtp({ id: error?.response?.data?.id, isTrue: true });
            }
            dispatch(loginFail(error?.response?.data?.message));
            toast.warn(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
};
