import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import "./hero.scss";
import qr from "../../asset/qr.svg";

import {
  ModalAds,
  ModalComponent,
  ModalSignup,
} from "../ModalComponent/ModalComponent";
import { EmailSection } from "../HeroFeild/EmailSection/EmailSection";
import { TextSection } from "../HeroFeild/TextSection";
import { WifiSection } from "../HeroFeild/WifiSection";
import { SmsSection } from "../HeroFeild/SmsSection";
import { TwitterSection } from "../HeroFeild/TwitterSection";
import { VcardSection } from "../HeroFeild/VcardSection/VcardSection";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import html2canvas from "html2canvas";
import QRCodeStyling from "qr-code-styling";

import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import qr1 from "../../Pages/user/Create/Images/1.png";
import qr2 from "../../Pages/user/Create/Images/3.png";
import qr3 from "../../Pages/user/Create/Images/4.png";
import qr4 from "../../Pages/user/Create/Images/8.png";

import transparentQr from "./Images/transparentqr.png";

import shape1 from "./Images/1.png";
import shape2 from "./Images/2.png";
import shape3 from "./Images/3.png";
import shape4 from "./Images/4.png";
import { useDispatch, useSelector } from "react-redux";
import { LOGO_DATA } from "./data";
import { Col, Row } from "react-bootstrap";
import { openMyQrType } from "../../redux/exportAllAction";
import DynamicCard from "../HeroFeild/DynamicCard";
import share from "../../asset/share.svg";
import share2 from "../../asset/share2.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";

import CloseIcon from "@mui/icons-material/Close";

import { ColorPicker, createColor } from "mui-color";
import { FaPenSquare } from "react-icons/fa";

const paletteCode = {
  red: "#ff0000",
  blue: "#0000ff",
  green: "#00ff00",
  yellow: "yellow",
  cyan: "cyan",
  lime: "lime",
  gray: "gray",
  orange: "orange",
  purple: "purple",
  black: "black",
  pink: "pink",
  darkblue: "darkblue",
};

const palette = {
  red: "#ff0000",
  blue: "#0000ff",
  green: "#00ff00",
  yellow: "yellow",
  cyan: "cyan",
  lime: "lime",
  gray: "gray",
  orange: "orange",
  purple: "purple",
  black: "black",
  white: "white",
  pink: "pink",
  darkblue: "darkblue",
  transparent: "transparent",
};

export const Hero = () => {
  const userState = useSelector((state) => state.user);

  const qrOpenState = useSelector((state) => state.cards.cardSelected);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const [typeSelected, setTypeSelected] = useState("static");
  const [framesDisplay, setFramesDisplay] = useState(false);
  const [colorDisplay, setColorDisplay] = useState(false);
  const [logoDisplay, setLogoDisplay] = useState(false);
  const [websiteLink, setWebsiteLink] = useState("");
  const [imgType, setImgType] = useState("jpg");
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isShareOpen, setIsShareOpen] = useState(false);

  const { currentUserId } = userState;

  const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      backgroundColor: "white",
      padding: "10px 10px 10px 10px",
    },
  }));

  const navigate = useNavigate();
  const handleChange = (section) => {
    dispatch(openMyQrType(section));
  };
  const handleTypeChange = (type) => {
    setTypeSelected(type);
  };

  const [age, setAge] = React.useState("url");

  const handleChangeMobile = (event) => {
    setAge(event.target.value);
  };
  const [qrText, setQrText] = useState("This is sample text");
  const [frame, setFrame] = useState("");
  const [logo, setLogo] = useState("");
  const [qrbg, setQrbg] = useState("rgb(0, 0, 0)");
  const [qrType, setQrType] = useState("square");
  const [bGcolor, setBGcolor] = useState(createColor("rgb(255, 255, 255)"));
  const [qrCreated, setQrCreated] = useState(false);
  const [inputs, setInputs] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  const [detail, setDetail] = useState({
    user: currentUserId,
    isDynamic: false,
  });

  const qrCode = new QRCodeStyling({
    width: 162,
    height: 162,
    type: "svg",
    image: logo,
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 2,
    },
    dotsOptions: {
      color: qrbg.hex ? `#${qrbg.hex}` : qrbg,
      type: qrType,
    },
    backgroundOptions: {
      color:
        bGcolor.raw === "transparent"
          ? bGcolor.raw
          : bGcolor?.css.backgroundColor,
    },
  });
  useEffect(() => {
    qrCode.append(ref.current);
    qrText?.length === 0 && setQrText("This is Sample text");
    localStorage.clear();
  }, [qrText, qrbg, logo, frame, qrType, bGcolor]);

  useEffect(() => {
    qrCode.update({
      data: qrText,
    });
  }, [qrText, qrbg, logo, frame, qrType, bGcolor]);

  const downloadQR = () => {
    let node = document.getElementById("frame-container");
    if (!frame) {
      node = document.getElementById("frame-container-reset");
      html2canvas(node, {
        backgroundColor: "transparent",
      }).then((canvas) => {
        let img = canvas.toDataURL(`image/${imgType}`);
        let link = document.createElement("a");
        link.download = "qr-code." + imgType;
        link.href = img;
        link.click();
      });
    }

    node = document.getElementById("frame-container");

    if (imgType) {
      html2canvas(node, {
        backgroundColor: "transparent",
      }).then((canvas) => {
        var img = canvas.toDataURL(`image/${imgType}`);
        var link = document.createElement("a");
        link.download = "qr." + imgType;
        link.href = img;
        link.click();
      });
    }
  };

  const handleOpenShare = () => {
    setIsShareOpen(!isShareOpen);

    let w = 50;
    if (!isShareOpen) {
      if (w > 299) clearInterval();
      w = w + 1;
      if (document.getElementById("animation-hero").style) {
        document.getElementById("animation-hero").style.width = w + "px";
      }
    } else {
      document.getElementById("animation-hero").removeAttribute("id");
    }
  };

  const handleGernate = async () => {
    const qrCode = document.getElementById("qrCodeEl");
    const img = qrCode?.toDataURL("image/png");

    setImageUrl(img);
    if (qrOpenState === "vcard") {
      setQrText(
        `BEGIN:VCARD\nVERSION:3.0\nN:${detail.firstName};${detail.lastName}
         \nN:${detail.mobileNumber};${detail.phoneNumber}\nFN:${detail.mobileNumber} ${detail.phoneNumber}\n
         \nN:${detail.faxNumber};${detail.email}\nFN:${detail.faxNumber} ${detail.email}\n
         \nN:${detail.company};${detail.job}\nFN:${detail.company} ${detail.job}\n
         \nN:${detail.streetAddress};${detail.cityAddress}\nFN:${detail.streetAddress} ${detail.cityAddress}\n
         \nN:${detail.zipAddress};${detail.stateAddress}\nFN:${detail.zipAddress} ${detail.stateAddress}\n
         \nN:${detail.countryAddress};${detail.websiteLink}\nFN:${detail.countryAddress} ${detail.websiteLink}\n
         ORG:END:VCARD`
      );
    }
    if (qrOpenState === "email") {
      setQrText(
        `MATMSG:TO:${detail.email};SUB:${detail.emailSubject};BODY:${detail.message};;`
      );
    }
    if (qrOpenState === "url") {
      setQrText(websiteLink);
    }
    if (qrOpenState === "text") {
      setQrText(detail.message);
    }
    if (qrOpenState === "sms") {
      setQrText(`smsto:${detail.mobileNumber}:${detail.description}`);
    }
    if (qrOpenState === "wifi") {
      setQrText(
        `WIFI:T:${detail.encryption};S:${detail.networkName};${
          detail.encryption !== "nopass" ? `P:${detail.password};` : ""
        }H:${detail.isHidden};`
      );
    }
    if (qrOpenState === "twitter") {
      setQrText(
        "https://twitter.com/" +
          (detail.twitterOption === "link"
            ? detail.username
            : "intent/tweet?text=" + detail.content)
      );
    }
  };

  const handleDetail = (e) => {
    if (e.target.value === "on") e.target.value = true;
    else if (e.target.value === "off") e.target.value = false;
    setDetail((prev) => ({
      ...prev,
      qrType: qrOpenState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTwitter = () => {
    window.open("https://twitter.com/intent/tweet?url=https://qrcodebrew.com/");
  };
  const handleFacebook = () => {
    window.open("https://www.facebook.com/share.php?u=https://qrcodebrew.com/");
  };
  const handleGoogle = () => {
    window.open("https://twitter.com/intent/tweet?url=https://qrcodebrew.com/");
  };

  useEffect(() => {
    setDetail((prev) => ({
      ...prev,
      frameNumber: frame,
      logoNumber: logo,
      qrBg: qrbg,
    }));
  }, [frame, logo, qrbg]);

  const handleChangeColor = (value) => {
    setQrbg(value);
  };
  const handleChangeBgColor = (value) => {
    setBGcolor(value);
  };

  const handleImageDelete = () => {
    setLogo("");
  };

  // const handleColorSelection = (value) => {
  //   setBGcolor(value);
  // };

  // console.log("bGcolor", bGcolor.name);

  let transparentImg = (
    <img  loading="lazy"   src={transparentQr} alt="transparent" className="trans_img" />
  );

  return (
    <div className="hero-container">
      <div className="hero-wrapper">
        {/* mobile */}
        <FormControl
          style={{
            borderBottom: "2px solid #00000017",
            width: "100%",
            margin: "0 0 30px 0",
          }}
          className="leftMobile"
          sx={{
            m: 1,
            minWidth: 120,
            "& .css-hdw1oc": { display: "none" },
          }}
          size="small">
          <Select
            value={age}
            style={{
              width: "50%",
              backgroundColor: "white",
              boxShadow: "0px 15px 30px rgba(92, 98, 116, 0.1)",
              borderRadius: "5px",
            }}
            onChange={handleChangeMobile}
            input={<BootstrapInput />}>
            <MenuItem
              onClick={() => {
                handleChange("url");
                setQrCreated(false);
              }}
              value="url">
              <i
                className="fas fa-globe iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              URL/Website
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("text");
                setQrCreated(false);
              }}
              value="text">
              <i
                className="fas fa-text-height iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              Text
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("vcard");
                setQrCreated(false);
              }}
              value="vcard">
              <i
                className="fas fa-address-card iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              VCard
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("email");
                setQrCreated(false);
              }}
              value="email">
              <i
                className="fas fa-envelope iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              Email
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("sms");
                setQrCreated(false);
              }}
              value="sms">
              <i
                className="fas fa-comment iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              SMS
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("wifi");
                setQrCreated(false);
              }}
              value="wifi">
              <i
                className="fas fa-wifi iconSelcet"
                style={{ marginRight: "5px" }}></i>{" "}
              Wifi
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange("twitter");
                setQrCreated(false);
              }}
              value="twitter">
              <i className="fab fa-twitter" style={{ marginRight: "5px" }}></i>{" "}
              Twitter
            </MenuItem>
          </Select>
          <div className="ncard">
            <div
              style={{
                alignItem: "center",
                display: "flex",
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px",
                boxShadow: "0px 15px 30px rgba(92, 98, 116, 0.1)",
              }}>
              <div className="more" style={{ marginRight: "10px" }}>
                <i
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                  className="fas fa-crown"></i>{" "}
                More Format
              </div>
              <div
                className="view"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "blue",
                }}>
                {" "}
                <Link to="/pricing" className="text-view">
                  View <i className="fas fa-arrow-right"> </i>{" "}
                </Link>
              </div>
            </div>
          </div>
        </FormControl>
        <div
          id="animation-hero"
          style={{ transition: "1s" }}
          className={`${isShareOpen ? "left1" : "left"}`}>
          {!isShareOpen && (
            <div
              id="textUrlHome"
              className="list"
              style={{ padding: "0 10px", transition: "1s" }}>
              <div
                style={{ transition: "1s" }}
                className={qrOpenState === "url" ? "item active" : "item"}
                onClick={() => {
                  handleChange("url");
                  setQrCreated(false);
                }}>
                <i className="fas fa-globe"></i> URL/Website
              </div>
              <div
                style={{ transition: "1s" }}
                className={qrOpenState === "text" ? "item active" : "item"}
                id="textQrHome"
                onClick={() => {
                  handleChange("text");
                  setQrCreated(false);
                }}>
                <i className="fas fa-text-height  fa-sm"></i> Text
              </div>
              <div
                style={{ transition: "1s" }}
                id="vCardQrHome"
                className={qrOpenState === "vcard" ? "item active" : "item"}
                onClick={() => {
                  handleChange("vcard");
                  setQrCreated(false);
                }}>
                <i className="fas fa-address-card  fa-sm"></i> VCard
              </div>
              <div
                style={{ transition: "1s" }}
                className={qrOpenState === "email" ? "item active" : "item"}
                id="emailQrHome"
                onClick={() => {
                  handleChange("email");
                  setQrCreated(false);
                }}>
                <i className="fas fa-envelope fa-sm"></i> Email
              </div>
              <div
                style={{ transition: "1s" }}
                id="smsQrHome"
                className={qrOpenState === "sms" ? "item active" : "item"}
                onClick={() => {
                  handleChange("sms");
                  setQrCreated(false);
                }}>
                <i className="fas fa-comment  fa-sm"></i> SMS
              </div>
              <div
                style={{ transition: "1s" }}
                id="wifiQrHome"
                className={qrOpenState === "wifi" ? "item active" : "item"}
                onClick={() => {
                  handleChange("wifi");
                  setQrCreated(false);
                }}>
                <i className="fas fa-wifi  fa-sm"></i> Wifi
              </div>
              <div
                style={{ transition: "1s" }}
                id="twitterQrHome"
                className={qrOpenState === "twitter" ? "item active" : "item"}
                onClick={() => {
                  handleChange("twitter");
                  setQrCreated(false);
                }}>
                <i className="fab fa-twitter  fa-sm"></i> Twitter
              </div>
            </div>
          )}
          {isShareOpen && (
            <div
              className="list2"
              style={{
                padding: "0 10px",
                display: "flex",
                flexDirection: "column",
                transition: "1s",
              }}>
              <TwitterIcon onClick={()=>{handleTwitter()}} className="social-icon" />
              <FacebookIcon onClick={()=>{handleFacebook()}} className="social-icon" />
              <GoogleIcon onClick={()=>{handleGoogle()}} className="social-icon" />
            </div>
          )}

          {!isShareOpen && (
            <div className="ncard" style={{ transition: "1s" }}>
              <div style={{ transition: "1s" }}>
                <div style={{ transition: "1s" }} className="more">
                  {" "}
                  More Format <i className="fas fa-crown"></i>{" "}
                </div>
                <div style={{ transition: "1s" }} className="view">
                  {" "}
                  <Link id="moreFormatView" to="/pricing" className="text-link">
                    View <i className="fas fa-arrow-right"> </i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div
            id="share-btn2"
            title="Share with friends on social media"
            className="share-btn"
            onClick={()=>{handleOpenShare()}}>
            <div className="share-btn2">
              {isShareOpen ? (
                <CloseIcon className="share-img3" />
              ) : (
                <>
                  <img  loading="lazy"  
                    style={{ color: "gray !important" }}
                    className="share-img"
                    src={share}
                    alt="share"
                  />
                  <img  loading="lazy"  
                    style={{ color: "gray !important" }}
                    className="share-img2"
                    src={share2}
                    alt="share"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="center">
          <h1 className="titleHeaderQr">QR Code Generator</h1>
          {qrOpenState === "url" && (
            <>
              <div className="top">
                <div
                  id="staticQrCodehome"
                  className={
                    typeSelected === "static" ? "selected item" : "item"
                  }
                  onClick={() => handleTypeChange("static")}>
                  Static QR Code
                </div>
                <div
                  id="dynamicQrCodehome"
                  className={
                    typeSelected === "dynamic" ? "selected item" : "item"
                  }
                  onClick={() =>
                    currentUserId ? navigate("/user/home") : setModalShow2(true)
                  }>
                  Dynamic QR Code
                  <i className="fas fa-crown" style={{ marginLeft: "5px" }}></i>
                </div>
              </div>
              <div className="bottom">
                <div className="middle">
                  <input
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      fontWeight: 400,
                    }}
                    className="heading1"
                    placeholder="Enter your website URL"
                    name="websiteLink"
                    autoFocus
                    onChange={(e) => {
                      setQrText(e.target.value);
                    }}
                  />
                  <i></i>
                  <div className="heading2">
                    Your QR code will be generated automatically
                  </div>
                </div>
                {/* <div
                                    className={`down ${
                                        isShareOpen && "down-share"
                                    }`}
                                >
                                    <div>
                                        <i
                                            className="fas fa-arrow-up mr-3"
                                            style={{ color: "#58a697" }}
                                        />
                                        <span
                                            onClick={() => setModalShow1(true)}
                                            style={{
                                                color: "#58A697",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {" "}
                                            Upload any file
                                        </span>
                                        (.jpg,.png,.mp3,.pdf etc)
                                    </div>
                                </div> */}
              </div>
            </>
          )}
          {qrOpenState === "email" && (
            <EmailSection
              top={true}
              setDetail={setDetail}
              detail={detail}
              handleDetail={handleDetail}
            />
          )}
          {qrOpenState === "dynamicCardMessage" && <DynamicCard top={false} />}
          {qrOpenState === "text" && (
            <TextSection
              top={true}
              handleDetail={handleDetail}
              // setInputs={setInputs}
              setDetail={setDetail}
              detail={detail}
            />
          )}
          {qrOpenState === "vcard" && (
            <VcardSection
              top={true}
              handleDetail={handleDetail}
              setDetail={setDetail}
              detail={detail}
              setInputs={setInputs}
            />
          )}
          {qrOpenState === "sms" && (
            <SmsSection
              top={true}
              handleDetail={handleDetail}
              setDetail={setDetail}
              detail={detail}
            />
          )}
          {qrOpenState === "wifi" && (
            <WifiSection
              top={true}
              setDetail={setDetail}
              detail={detail}
              handleDetail={handleDetail}
            />
          )}
          {qrOpenState === "twitter" && (
            <TwitterSection
              top={true}
              setDetail={setDetail}
              detail={detail}
              handleDetail={handleDetail}
            />
          )}
          {qrOpenState !== "url" && qrOpenState !== "dynamicCardMessage" && (
            <button
              className={qrOpenState === "vcard" && "phoneVcardBtn"}
              onClick={()=>{handleGernate()}}>
              Generate
            </button>
          )}
        </div>
        <div className="hero-right">
          {qrText?.length === 0 ? (
            <img  loading="lazy"   src={qr} alt="" />
          ) : (
            <div>
              {frame.length === 0 ? (
                <div id="frame-container-reset" className="p-4">
                  <div
                    ref={ref}
                    style={{
                      transform:
                        qrOpenState === "vcard" && qrText.length > 10
                          ? `scale(1.1)`
                          : `scale(1)`,
                    }}
                  />
                </div>
              ) : null}
              {frame === "0" ? (
                <div
                  className="home-frame-container"
                  id="frame-container"
                  style={{ border: `3px solid ${qrbg}` }}>
                  <div ref={ref} alt="qr" className="home-frame-image" />
                  <div
                    className="frame-text"
                    style={{
                      background: qrbg,
                      marginTop: "10px",
                      borderRadius: "6px",
                    }}>
                    Scan Me
                  </div>
                </div>
              ) : null}
              {frame === "1" ? (
                <div
                  className="home-frame-container-3"
                  id="frame-container"
                  style={{ border: `5px solid ${qrbg}` }}>
                  <div className="frame-text-3" style={{ background: qrbg }}>
                    Scan Me
                  </div>
                  <div ref={ref} alt="qr" className="home-frame-image-3" />
                </div>
              ) : null}
              {frame === "2" ? (
                <div
                  style={{
                    position: "relative",
                    width: "115%",
                  }}
                  id="frame-container">
                  <div
                    className="frame-container-4"
                    style={{ border: `3px solid ${qrbg}` }}>
                    <div className="image-container">
                      {" "}
                      <div
                        ref={ref}
                        alt="qr"
                        className="frame-image-4 d-flex justify-content-center"
                      />
                    </div>
                  </div>
                  <div className="frame-text-4" style={{ background: qrbg }}>
                    Scan me
                  </div>
                </div>
              ) : null}
              {frame === "3" ? (
                <div
                  className="frame-container-4"
                  style={{ backgroundColor: qrbg }}
                  id="frame-container">
                  <div
                    className="frame-text-4"
                    style={{ backgroundColor: qrbg }}>
                    Scan Me
                  </div>
                  <div
                    className="image-container"
                    style={{ backgroundColor: qrbg }}>
                    <div ref={ref} className="frame-image-4" />
                  </div>
                </div>
              ) : null}
            </div>
          )}
          <img  loading="lazy"   src="" id="imgimg" alt="" />
          <div className="filterContainer">
            <div className="filter">
              <div className="title">
                <div
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => setFramesDisplay(!framesDisplay)}>
                  Frames
                </div>
                <i
                  className={
                    framesDisplay ? "fas fa-chevron-up" : "fas fa-chevron-down"
                  }
                  onClick={() => setFramesDisplay(!framesDisplay)}></i>
              </div>
              <div
                className={framesDisplay ? "show option" : "hideItem option"}>
                <img  loading="lazy"  
                  src={qr1}
                  onClick={() => setFrame("0")}
                  className={frame === "0" ? "optionItem active" : "optionItem"}
                  alt="."
                />
                <img  loading="lazy"  
                  src={qr2}
                  onClick={() => setFrame("1")}
                  className={frame === "1" ? "optionItem active" : "optionItem"}
                  alt="."
                />
                <img  loading="lazy"  
                  src={qr3}
                  onClick={() => setFrame("2")}
                  className={frame === "2" ? "optionItem active" : "optionItem"}
                  alt="."
                />
                <img  loading="lazy"  
                  src={qr4}
                  onClick={() => setModalShow1(true)}
                  className={frame === "3" ? "optionItem active" : "optionItem"}
                  alt="."
                />
              </div>
            </div>
            <div className="filter">
              <div className="title">
                <div
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => setColorDisplay(!colorDisplay)}>
                  Shapes & Colors
                </div>
                <i
                  className={
                    colorDisplay ? "fas fa-chevron-up" : "fas fa-chevron-down"
                  }
                  onClick={() => setColorDisplay(!colorDisplay)}></i>
              </div>
              <div className={colorDisplay ? "show option" : "hideItem option"}>
                <Col>
                  <Row
                    style={{
                      justifyContent: "space-evenly",
                    }}>
                    <img  loading="lazy"  
                      src={shape1}
                      onClick={() => setQrType("rounded")}
                      className={
                        qrType === "rounded"
                          ? "optionItem active fit-image"
                          : "optionItem fit-image"
                      }
                      alt="."
                    />
                    <img  loading="lazy"  
                      src={shape2}
                      onClick={() => setQrType("dots")}
                      className={
                        qrType === "dots"
                          ? "optionItem active fit-image"
                          : "optionItem fit-image"
                      }
                      alt="."
                    />
                    <img  loading="lazy"  
                      src={shape3}
                      onClick={() => setQrType("2")}
                      className={
                        qrType === "2"
                          ? "optionItem active fit-image"
                          : "optionItem fit-image"
                      }
                      alt="."
                    />
                    <img  loading="lazy"  
                      src={shape4}
                      onClick={() =>
                        currentUserId ? setQrType("3") : setModalShow1(true)
                      }
                      className={
                        qrType === "3"
                          ? "optionItem active fit-image"
                          : "optionItem fit-image"
                      }
                      alt="."
                    />
                  </Row>
                  <Row
                    style={{
                      marginTop: "10px",
                      justifyContent: "space-evenly",
                    }}
                    className={
                      qrbg?.hex === "000000" ? "colorItem2" : "colorItem"
                    }>
                    <div
                      onClick={() => setQrbg("#3F4E4F")}
                      className="optionItem"
                      alt="."
                      style={{
                        background: "#3F4E4F",
                        border: qrbg == "#3F4E4F" ? "3px solid #53a697" : 0,
                      }}></div>
                    <div
                      onClick={() => setQrbg("#570A57")}
                      className="optionItem"
                      alt="."
                      style={{
                        background: "#570A57",
                        border: qrbg == "#570A57" ? "3px solid #53a697" : 0,
                      }}></div>
                    <div
                      onClick={() => setQrbg("rgb(169, 11, 232)")}
                      className="optionItem"
                      alt="."
                      style={{
                        background: "rgb(169, 11, 232)",
                        border:
                          qrbg == "rgb(169, 11, 232)" ? "3px solid #53a697" : 0,
                      }}></div>

                    <ColorPicker
                      palette={paletteCode}
                      value={qrbg || "rgb(232, 11, 125)"}
                      onChange={handleChangeColor}
                      title="Choose QR Color"
                    />
                  </Row>
                  <div className="backgroundItem">
                    <ColorPicker
                      palette={palette}
                      value={bGcolor || "rgb(232, 11, 125)"}
                      onChange={handleChangeBgColor}
                    />
                    {/* <div
                      palette={palette}
                      onChange={handleChangeBgColor}
                      className="selectionPen">
                      <DrawOutlinedIcon />
                    </div> */}

                    {bGcolor.name === "transparent" ? (
                      <>
                        <div
                          palette={palette}
                          onChange={handleChangeBgColor}
                          className="selectionPen">
                          <DrawOutlinedIcon />
                        </div>
                        <div className="transparentImgContainer">
                          {transparentImg}
                        </div>
                      </>
                    ) : (
                      <div
                        palette={palette}
                        onChange={handleChangeBgColor}
                        className="selectionPen">
                        <DrawOutlinedIcon />
                      </div>
                    )}

                    <p>Choose background Color</p>
                  </div>
                </Col>
              </div>
            </div>
            <div className="filter">
              <div className="title">
                <div
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => setLogoDisplay(!logoDisplay)}>
                  Logos
                </div>
                <i
                  className={
                    logoDisplay ? "fas fa-chevron-up" : "fas fa-chevron-down"
                  }
                  onClick={() => setLogoDisplay(!logoDisplay)}></i>
              </div>
              <div className={logoDisplay ? "show" : "hideItem"}>
                <div className="d-flex justify-content-between option">
                  <img  loading="lazy"  
                    src={LOGO_DATA[0]}
                    onClick={() => setLogo("")}
                    className={logo === "" ? "optionItem active" : "optionItem"}
                    alt="."
                  />
                  <img  loading="lazy"  
                    src={LOGO_DATA[1]}
                    onClick={() => setLogo(LOGO_DATA[1])}
                    className={
                      logo === LOGO_DATA[1] ? "optionItem active" : "optionItem"
                    }
                    alt="."
                  />
                  <img  loading="lazy"  
                    src={LOGO_DATA[2]}
                    onClick={() => setLogo(LOGO_DATA[2])}
                    className={
                      logo === LOGO_DATA[2] ? "optionItem active" : "optionItem"
                    }
                    alt="."
                  />
                  <svg
                    onClick={handleImageDelete}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ cursor: "pointer" }}
                    width={30}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                    />
                  </svg>
                </div>

                <div className="down mt-1">
                  <div>
                    <span
                      style={{
                        color: "#0d6efd",
                        marginLeft: "5px",
                        marginRight: "5px",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                      onClick={() => setModalShow1(true)}>
                      Upload your own logo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="imgType">
            <div
              className={imgType === "jpg" ? "item selected" : "item"}
              id="jpgQRHome"
              onClick={() => setImgType("jpg")}>
              JPG
            </div>
            <div
              id="pngQRHome"
              className={imgType === "png" ? "item selected" : "item"}
              onClick={() => setImgType("png")}>
              PNG
            </div>
            <div
              id="svgQRHome"
              className={imgType === "svg" ? "item selected" : "item"}
              onClick={() => setImgType("svg")}>
              SVG
            </div>
          </div>
          <a
            href={imageUrl}
            download={`qrcode.${imgType}`}
            className="download_anchor">
            <button
              id="downloadQrHome"
              onClick={() => {
                downloadQR();
                setModalShow(true);
                handleGernate();
              }}>
              {/* <BiDownload
                style={{
                  marginRight: "5px",
                  width: "18px",
                  height: "18px",
                  marginTop: "-3px",
                }}
              /> */}
              Download
            </button>
          </a>
          <ModalComponent show={modalShow} onHide={() => setModalShow(false)} />
          <ModalAds
            show={modalShow2}
            onHide={() => {
              setModalShow2(false);
            }}
          />

          <ModalSignup
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            close={() => setModalShow1(false)}
            mainHeading=""
            spanHeading="Attract more scans with "
            spanHeadingLastWord="frames"
            mainBody={`By adding our call-to-action frames, you can urge your audience to take an action such as "Scan Me" ," View PDF" ,"Rate Us" ,and more `}
            ImageSrc="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg?w=2000"
          />
        </div>
      </div>
    </div>
  );
};
