import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar } from "../../Component/navbar/Navbar";
import { Footer } from "../../Component/Footer/Footer";
import { KnowAbout } from "../../Component/KnowAbout/KnowAbout";
import Network from "../../asset/business/network.png";
import Grow from "../../asset/business/phone.png";
import WorkSpace from "../../asset/business/workspace.png";
import Customer from "../../asset/business/customer.png";
import Banner from "../../Component/Banner/Banner";
import { UserNavbar } from "../../Component/user/UserNavbar";
import { Helmet } from "react-helmet";
import { QRType } from "../../Component/QRType/QRType";
import "./style.css";
import "../SolutionCard/style.css";
import "../Guide/style.css";
import "../DigitalMedia/style.css";

const EducationalInstitute = () => {
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const userState = useSelector((state) => state.user);
  const currentUserId = userState.currentUserId;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const navigateTo = () => {
    if (!currentUserId) {
      return navigate("/signUp", {
        state: {
          path: "/user/create/feild/dynamic/appDynamic",
        },
      });
    }
    navigate("/user/create/feild/dynamic/appDynamic");
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title>QR Codes for Educational Institutions | QRcodebrew</title>
        <meta
          name="description"
          content="Check out QR Codes for educational institutions and learn how to use customizable QR Codes in your school or universities. Create your free account now!
          ."
        />
        <meta
          name="keywords"
          content="QR Codes for Educational Institutions
          QR Codes in Education: Enhancing Learning and Teaching Methods
          How to create a QR code using a professional QR code generator
          QR Codes for Educational Institutions
"
        />
        <meta name="theme-color" content="#008f68" />
        <link
          className="section-textBlue"
          rel="canonical"
          href="/qr-code-business-card"
        />
      </Helmet>
      {currentUserId ? (
        <UserNavbar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      ) : (
        <Navbar />
      )}
      <div
        className="qrbrew-main businessCard mobile-width-100 margin-t-zero"
        style={{ overflow: "clip" }}
      >
        <section
          style={currentUserId ? { marginTop: "7vh" } : { marginTop: "1vh" }}
          className="mobile-mt-0"
        >
          <Banner
            Title="Educational Institutions"
            des="Engaging more with students. Get inspired to use these creative QR code ideas for your school or university.
						"
          />
        </section>

        <section className="section-guide passup-section zero-display">
          {/* <div>
                        <p className="passupText text-center">Pass Up : </p>
                    </div> */}

          <div
            className="container d-flex justify-content-evenly guide-pt-20"
            style={{ textAlign: "center" }}
          >
            <div className="row">
              <div className="col">
                <a href="#benefits">
                  <p>
                    <nobr>Education</nobr>
                  </p>
                </a>
              </div>
              <div className="col">
                <a href="#how-to-create-a-qr">
                  <p>
                    <nobr>Create QR Code</nobr>
                  </p>
                </a>
              </div>
              <div className="col">
                <a href="#best-practices">
                  <p>
                    <nobr>Marketing</nobr>
                  </p>
                </a>
              </div>
              <div className="col" style={{ justifyContent: "center" }}>
                <a href="#qrType">
                  <p style={{ width: "237px" }}>
                    <nobr>Types of QR codes</nobr>
                  </p>
                </a>
              </div>
              <div className="col">
                <a href="#faq">
                  <p>
                    <nobr>FAQ</nobr>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="container-lg">
          <h4 className="heading h-size-22-mobile d-block-mobile" id="benefits">
            QR Codes in Education :
            <span className="gold-color h-size-22-mobile">
              Enhancing Learning and Teaching Methods
            </span>
          </h4>
          <p className="sub-headings p-size-14-mobile">
            QR codes have revolutionised education by enhancing both learning
            and teaching methods. These two-dimensional barcodes enable quick
            access to digital resources, making it easier for students to access
            supplementary materials, quizzes, and interactive content. Teachers
            can incorporate QR codes into their lessons to provide instant
            feedback, promote engagement, and streamline information
            dissemination. This technology fosters a more dynamic and efficient
            educational experience, benefiting educators and learners.
          </p>
          {/* first comp start */}
          <section className="reverseCol section-main row section-shadow ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3 className="section-heading">
                VCARD PLUS QR CODE Expand your networking opportunities
              </h3>
              <p className="section-text p-size-14-mobile">
                Simplify networking with other teachers or students by adding a
                vCard Plus QR Code to your name tag. To{" "}
                <Link to="/signUp" className="section-textBlue">
                  {" "}
                  scan the QR code
                </Link>{" "}
                use a mobile device. With a QR reader, the information contained
                in the code can be easily accessed. Anyone can instantly save
                their contact information to their smartphone’s address book
                without the need to manually type it in. This is a convenient
                way for students, parents, and other faculty members to connect
                with you. Say goodbye to the frustration of lost or forgotten
                <Link to="/qr-code-business-card" className="section-textBlue">
                  {" "}
                  QR Code on business cards
                </Link>{" "}
                and hello to effortless and efficient networking.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sectiong-image b-card-img-hover position-relative">
                <img  loading="lazy"   className="image-container" src={Network} alt="network" />
                <div></div>
                <button
                  onClick={() => navigateTo()}
                  className="create-qr-btn-copy"
                >
                  Create this QR Code
                </button>
              </div>
            </div>
          </section>
          <hr />
          <section className="section-main row section-shadow ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sectiong-image2 b-card-img-hover-left position-relative">
                <img  loading="lazy"   className="image-container" src={Grow} alt="network" />
                <button
                  onClick={() => navigateTo()}
                  className="create-qr-btn-left"
                >
                  Create this QR Code
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3 className="section-heading">
                EVENT QR CODE Promote your events conveniently
              </h3>
              <p className="section-text p-size-14-mobile">
                Adding an event QR code to banners or posters can make it easier
                for your students to access all the essential information about
                upcoming events. With a single scan, they can get all the
                details conveniently. You can customize the QR code quickly and
                include information such as the date, time, address, contact
                person, and description of the upcoming field trip. Students can
                save the event on their smartphone calendar and access the
                details anytime.
              </p>
            </div>
          </section>
          {/* Second comp End */}
          <hr />
          {/* Third comp Start */}
          <section className="reverseCol section-main section-shadow row ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3 className="section-heading">
                PDF QR CODE Help your students to be more prepared
              </h3>
              <p className="section-text p-size-14-mobile">
                Include a QR code on your syllabus for easy scanning and saving
                to your smartphone. To have a digital version, share the
                document with fellow students and have it ready if It's easy to
                look up information on your PDF, and you can always upload a new
                one if the information changes without
                <Link to="/qr-code-marketing-card" className="section-textBlue">
                  {" "}
                  creating a different QR code
                </Link>{" "}
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sectiong-image b-card-img-hover position-relative">
                <img  loading="lazy"  
                  className="image-container"
                  src={WorkSpace}
                  alt="WorkSpace"
                />
                <button className="create-qr-btn">Create this QR Code</button>
              </div>
            </div>
          </section>
          {/* Third comp End*/}
          <hr />
          {/* Fourth comp start */}
          <section className="section-main row section-shadow ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sectiong-image2 b-card-img-hover-left position-relative">
                <img  loading="lazy"  
                  className="image-container"
                  src={Customer}
                  alt="Customer"
                />
                <button className="create-qr-btn-left">
                  Create this QR Code
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3 className="section-heading">
                VIDEO QR CODE Introduce yourself with videos
              </h3>
              <p className="section-text p-size-14-mobile">
                An office door sign with a{" "}
                <Link to="/qr-code-digital-media" className="section-textBlue">
                  {" "}
                  video QR code
                </Link>{" "}
                can be an excellent tool for helping students become familiar
                with you before they attend your class or seminar. By scanning
                the QR code, students can learn about your areas of expertise,
                teaching style, class structure, and how to contact you when
                you're out of the office. By providing an introductory video,
                it's easier for students to decide which teacher or lecturer to
                sign up for in the upcoming semester or school year.
              </p>
            </div>
          </section>
          {/* Fourth comp End */}
        </div>

        <hr />
        <section id="how-to-create-a-qr">
          <div className="container-lg">
            <h4 className="heading h-size-22-mobile d-block-mobile">
              How to create a QR code using a
              <span className="gold-color h-size-22-mobile">
                professional QR code
              </span>
              generator
            </h4>

            <div className="row">
              <ul
                id="progressbar"
                className="mobile-flex"
                style={{ textAlign: "center" }}
              >
                <li className="mobile-width-100 margin-t-20">
                  <p>
                    First, choose a QR code generator: Many online QR code
                    generators are free. Choose one you prefer and offer the
                    type of QR code you need (e.g., URL, vCard, etc.).
                  </p>
                </li>
                <li className="mobile-width-100 margin-t-20">
                  <p>
                    Once you have selected a QR code generator, enter the data
                    you want to include in the QR code. It might be your website
                    URL, contact information, or something else entirely.
                  </p>
                </li>
                <li className="mobile-width-100 margin-t-20">
                  <p>
                    Customize the design of your QR code to make it visually
                    promising and relevant to your business. You can change the
                    color, add a logo, and adjust the size and shape.
                  </p>
                </li>
                <li className="mobile-width-100 margin-t-20">
                  <p>
                    Once you are happy with the design of your{" "}
                    <Link
                      className="section-textBlue"
                      to="/login"
                    >
                      {" "}
                      QR code, download
                    </Link>{" "}
                    it in .jpg format and save it to your computer.
                  </p>
                </li>
                <li className="mobile-width-100 margin-t-20">
                  <p>
                    Finally, add the QR code to your business card design. You
                    can do this by placing it in a prominent location on the
                    card, such as the back or corner.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Fifth comp Start */}
        <section className="bg-grey" id="best-practices">
          <div className="container-lg">
            <h4 className="heading h-size-22-mobile d-block-mobile">
              Your all-in-one
              <span className="gold-color h-size-22-mobile">
                QR Code marketing
              </span>
              platform
            </h4>
            <p className="sub-headings mx-4 p-size-14-mobile">
              Customise QR Codes with company branding, logo, scan stats, and
              post-print content editing
            </p>
            <ul>
              <li>
                {/* <h3 className="section-heading"> Instructions</h3> */}
                <p className="section-text w-100 p-size-14-mobile">
                  {/* <span className="p-size-14-mobile">
                                        {" "}
                                        Meet Bernhard loyd
                                    </span>*/}
                  To create high-quality QR codes that can be used in your
                  classroom, please follow the instructions carefully and ensure
                  all necessary information is included. It’s essential to use
                  professional QR code software like QRcodebrew.
                </p>
              </li>
              <li>
                {/* <h3 className="section-heading">Frame it</h3> */}
                <p className="section-text w-100 p-size-14-mobile">
                  You can use the freemium version for unlimited static and
                  three dynamic QR codes. Or you can subscribe to one of their
                  tiered plans to access advanced software features. With a
                  QRcodebrew account, you can generate, manage, and track your
                  QR code campaigns to make your virtual class easier to handle.
                </p>
              </li>
              <li>
                {/* <h3 className="section-heading">
                  Add a logo inside your QR Code
                </h3> */}
                <p className="section-text w-100 p-size-14-mobile">
                  If you want to know how to create a QR code from QRcodebrew,
                  here’s what you should do:
                </p>
                <ol>
                  <li>
                    {" "}
                    <p className="section-text w-100 p-size-14-mobile">
                      Open the{" "}
                      <Link
                        to="/"
                        className="section-textBlue"
                      >
                        {" "}
                        <bold
                          style={{ textDecoration: "underline" }}
                          className="sub-headings"
                        >
                          free QR code generator online,
                        </bold>
                      </Link>{" "}
                      log in, or sign up for an account
                    </p>
                  </li>
                  <li>
                    <p className="section-text w-100 p-size-14-mobile">
                      {" "}
                      Choose a QR code solution and enter the required data
                    </p>
                  </li>
                  <li>
                    <p className="section-text w-100 p-size-14-mobile">
                      {" "}
                      Switch to{" "}
                      <bold
                        className="sub-headings"
                        style={{ fontStyle: "italic" }}
                      >
                        Dynamic QR code
                      </bold>{" "}
                      and click the{" "}
                      <Link to="/login" className="section-textBlue">
                        {" "}
                        <bold
                          style={{ textDecoration: "underline" }}
                          className="sub-headings"
                        >
                          Generate QR code
                        </bold>
                      </Link>{" "}
                      button
                    </p>
                  </li>
                  <li>
                    <p className="section-text w-100 p-size-14-mobile">
                      Customize your QR code
                    </p>
                  </li>
                  <li>
                    <p className="section-text w-100 p-size-14-mobile">
                      Run a test scan before downloading the QR code image
                    </p>
                  </li>
                </ol>
              </li>
              {/* <li>
                <h3 className="section-heading">Save your designs</h3>
                <p className="section-text w-100 p-size-14-mobile">
                  Don't forget to save your QR code designs! It will allow you
                  to reuse them for future marketing campaigns, making the
                  process quicker and more efficient. It also ensures you have a
                  backup copy in case of loss or damage.
                </p>
              </li>
              <li>
                <h3 className="section-heading">Customizable URL</h3>
                <p className="section-text w-100 p-size-14-mobile">
                  Make sure to use a customizable URL when creating your QR
                  code. It helps you change the code's destination anytime
                  without reprinting your business cards. Using a customizable
                  URL, you can also trace the performance of your QR code and
                  see how many people are scanning it.
                </p>
              </li> */}
            </ul>
          </div>
        </section>
        {/* Fifth comp End */}
        {/* Sixth comp Start */}
        <section id="qrType">
          <QRType />
        </section>
        {/* <section id="business-cards-in-action">
          <div className="container">
            <h4 className="heading h-size-22-mobile d-block">
              <nobr className="h-size-22-mobile heading">
                QR Codes on
                <span className="gold-color h-size-22-mobile">
                  Business Cards
                </span>
                in Action
              </nobr>{" "}
            </h4>
            <p className="sub-headings mx-4 p-size-14-mobile">
              QR codes are a promising tool that can benefit businesses and
              individuals across various industries. Whether you want to improve
              customer experiences, streamline operations, or market your brand,{" "}
              <Link to="qr-code-solution-card" className="sub-headings">
                {" "}
                QR codes offer a effective solution
              </Link>
              . So why not join today's QR code revolution and watch how they
              can transform your business?
            </p>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Card
                  img={Services}
                  title="Business Services"
                  description="QR codes are becoming increasingly popular in business services to improve efficiency and productivity. By embedding QR codes on business cards, companies can quickly and easily share their contact information with potential clients, suppliers, or partners. "
                  alt="QR Codes On Business Cards"
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Card
                  img={Restaurants}
                  description="For the food industry, QR codes are a game-changer. By placing a QR code on the table, restaurant owners can provide customers instant access to their menu, online ordering system, or loyalty program."
                  title="Restaurants"
                  alt="QR Codes On Business Cards"
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Card
                  img={Government}
                  description="QR codes have a wide range of applications in the government sector. QR codes can also make government services more accessible, allowing citizens to quickly and easily pay bills, access health records, or apply for permits online."
                  title="Government"
                  alt="QR Codes On Business Cards"
                />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <Card
                  img={Musicians}
                  description="QR codes can be a powerful marketing tool for musicians and artists. By embedding a code on their promotional materials, such as flyers or posters, they can direct fans to their website, social media pages, or online store."
                  title="Musicians & Artists"
                  alt="QR Codes On Business Cards"
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Card
                  img={Photographers}
                  description="QR codes are revolutionizing how photographers and videographers share their work with clients. Photographers can direct potential clients to their website or online gallery by placing a code on a business card or portfolio."
                  title="Photographers & Videographers"
                  alt="QR Codes On Business Cards"
                />
              </div>
            </div>
          </div>
        </section> */}
        {/* Sixth comp End */}

        <section className="container" id="faq">
          <KnowAbout faq={true} />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default EducationalInstitute;
