import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";

import { Apps } from "../../Pages/DynamicCard/Apps/Apps";
import { Business } from "../../Pages/DynamicCard/Business/Business";
import Coupon from "../../Pages/DynamicCard/Coupon/Coupon";
import { Event } from "../../Pages/DynamicCard/Event/Event";
import { Facebook } from "../../Pages/DynamicCard/Facebook/Facebook";
import { Feedback } from "../../Pages/DynamicCard/Feedback/Feedback";
import { Images } from "../../Pages/DynamicCard/Images/Images";
import { Mp4 } from "../../Pages/DynamicCard/Mp4/Mp4";
import { Pdf } from "../../Pages/DynamicCard/Pdf/Pdf";
import { Rating } from "../../Pages/DynamicCard/Rating/Rating";
import { SocialMedia } from "../../Pages/DynamicCard/SocialMedia/SocialMedia";
import Vcard from "../../Pages/DynamicCard/Vcard/Vcard";
import { Video } from "../../Pages/DynamicCard/Video/Video";
import { Email } from "../../Pages/StaticCard/Email/Email";
import { Sms } from "../../Pages/StaticCard/Sms";
import { Text } from "../../Pages/StaticCard/Text";
import { UrlStatic } from "../../Pages/StaticCard/UrlStatic";
import { VcardStatic } from "../../Pages/StaticCard/VcardStatic";
import { Wifi } from "../../Pages/StaticCard/Wifi";
import { Twitter } from "../StaticCard/TwitterCard";
import { publicRequest } from "../../requestMethod";
import axios from "axios";

export const CardDisplay = () => {
    const params = useParams();
    const [typeSelected, setTypeSelected] = useState("preview");

    const [inputs, setInputs] = useState({});

    const [imgType, setImgType] = useState("jpg");
    const [qrText, setQrText] = useState("");

    const fetchQr = async () => {
        const res = await publicRequest("/qr/" + params.id);
        const baseUrl = process.env.REACT_APP_BACKEND_URL;
        const url = baseUrl + "" + "/qr/" + "" + params.id;
        const data = {
            location: "delhi",
            scan: res?.data?.qr?.qrType.includes("Static") ? false : true,
        };
        data.scan &&
            axios.patch(url, data).then((res) => {
                setInputs(res.data.qr);
            });
    };
    useEffect(() => {
        fetchQr();
    }, []);

    return (
        <>
            <div className="userContainer">
                <div className="userWrapper">
                    <div className="userRight">
                        <div
                            className="createContainer"
                            style={{ alignItems: "center" }}
                        >
                            {typeSelected === "preview" &&
                                inputs.qrType === "urlStatic" && (
                                    <UrlStatic inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "smsStatic" && (
                                    <Sms inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "wifiStatic" && (
                                    <Wifi inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "emailStatic" && (
                                    <Email inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "textStatic" && (
                                    <Text inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "vcardStatic" && (
                                    <VcardStatic inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "twitterStatic" && (
                                    <Twitter inputs={inputs} />
                                )}

                            {typeSelected === "preview" &&
                                inputs.qrType === "vcardDynamic" && (
                                    <Vcard inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "facebookDynamic" && (
                                    <Facebook inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "pdfDynamic" && (
                                    <Pdf inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "videoDynamic" && (
                                    <Video inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "appDynamic" && (
                                    <Apps inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "imageDynamic" && (
                                    <Images inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "couponDynamic" && (
                                    <Coupon inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "mp4Dynamic" && (
                                    <Mp4 inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "businessDynamic" && (
                                    <Business inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "ratingDynamic" && (
                                    <Rating inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "socialMediaDynamic" && (
                                    <SocialMedia inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "feedbackDynamic" && (
                                    <Feedback inputs={inputs} />
                                )}
                            {typeSelected === "preview" &&
                                inputs.qrType === "eventDynamic" && (
                                    <Event inputs={inputs} />
                                )}

                            {typeSelected === "qrcode" && (
                                <>
                                    {qrText?.length > 0 && (
                                        <QRCode
                                            id="qrCodeEl3"
                                            renderAs="png"
                                            value={qrText}
                                        />
                                    )}

                                    <div className="imgType">
                                        <div
                                            className={
                                                imgType === "jpg"
                                                    ? "item selected"
                                                    : "item"
                                            }
                                            onClick={() => setImgType("jpg")}
                                        >
                                            JPG
                                        </div>
                                        <div
                                            className={
                                                imgType === "png"
                                                    ? "item selected"
                                                    : "item"
                                            }
                                            onClick={() => setImgType("png")}
                                        >
                                            PNG
                                        </div>
                                        <div
                                            className={
                                                imgType === "svg"
                                                    ? "item selected"
                                                    : "item"
                                            }
                                            onClick={() => setImgType("svg")}
                                        >
                                            SVG
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
