import { useRef, useState } from "react";
import "./Video.scss";
import { useSelector } from "react-redux";
import { ModalPopUp } from "../../../Component/ModalComponent/ModalComponent";

export const Video = ({ inputs }) => {
    const { dynamicColor, textColor, buttonColor } = useSelector(
        (state) => state.url
    );
    const [isShow, setIsShow] = useState(false);
    const containerRef = useRef();

    return (
        <div className="videoContainer" ref={containerRef}>
            <div
                className="videoWrapper"
                style={{
                    backgroundColor:
                        dynamicColor?.color1 || inputs?.BGcolor || "#006e7f",
                }}
            >
                <div
                    className="top"
                    style={{
                        color:
                            textColor?.text || inputs?.colorPrimary || "#fff",
                        backgroundColor:
                            dynamicColor?.color2 || inputs?.color || "#006e7f",
                    }}
                >
                    {!inputs?.company ? "Get More Videos" : inputs?.company}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {inputs?.socialLinks?.map((item) => (
                        <div
                            className="icon-div"
                            onClick={() => window.open(item?.url)}
                            style={{
                                backgroundColor: "#cce0ff",
                                cursor: "pointer",
                                margin: "10px",
                            }}
                        >
                            {item.name === "facebook" && (
                                <i className="fab fa-facebook socialIcon-size" />
                            )}
                            {item.name === "tiktok" && (
                                <i className="fab fa-tiktok socialIcon-size" />
                            )}
                            {item.name === "telegram" && (
                                <i className="fab fa-telegram socialIcon-size" />
                            )}
                            {item.name === "yelp" && (
                                <i className="fab fa-yelp socialIcon-size" />
                            )}
                            {item.name === "google" && (
                                <i className="fab fa-google socialIcon-size" />
                            )}
                            {item.name === "github" && (
                                <i className="fab fa-github socialIcon-size" />
                            )}
                            {item.name === "spotify" && (
                                <i className="fab fa-spotify socialIcon-size" />
                            )}
                            {item.name === "pinterest" && (
                                <i className="fab fa-pinterest socialIcon-size" />
                            )}
                            {item.name === "xing" && (
                                <i className="fab fa-xing socialIcon-size" />
                            )}
                            {item.name === "vimeo" && (
                                <i className="fab fa-vimeo-v socialIcon-size" />
                            )}
                            {item.name === "reddit" && (
                                <i className="fab fa-reddit socialIcon-size" />
                            )}
                            {item.name === "instagram" && (
                                <i class="fab fa-instagram socialIcon-size" />
                            )}
                            {item.name === "twitter" && (
                                <i class="fab fa-twitter socialIcon-size" />
                            )}
                            {item.name === "whatsapp" && (
                                <i class="fab fa-whatsapp socialIcon-size" />
                            )}
                            {item.name === "linkedin" && (
                                <i class="fab fa-linkedin socialIcon-size" />
                            )}
                            {item.name === "youtube" && (
                                <i class="fab fa-youtube socialIcon-size" />
                            )}
                            {item.name === "applemusic" && (
                                <i className="fab fa-applemusic socialIcon-size" />
                            )}
                            {item.name === "snapchat" && (
                                <i className="fab fa-snapchat socialIcon-size" />
                            )}
                        </div>
                    ))}
                </div>
                <div className="videoContainer">
                    {!inputs?.videoURL[0]?.url ||
                    inputs?.videoURL[0]?.url?.length === 0 ? (
                        <div className="video">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/nRpNLU0wrE4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    ) : (
                        <div className="video">
                            {/* <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${inputs?.videoURL[0]?.url?.slice(
                                    -11
                                )}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            /> */}
                              <div className="content" dangerouslySetInnerHTML={{__html: inputs?.videoURL[0]?.iframe}}></div>
                        </div>
                    )}

                    <div className="desc">
                        <div
                            className="video-qr-heading"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#fff",
                            }}
                        >
                            {!inputs?.headline
                                ? "UI & UX Designing"
                                : inputs?.headline}
                        </div>
                        <div
                            className="content"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#fff",
                            }}
                        >
                            {!inputs?.description
                                ? "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit omnis amet quisquam est quo et dolorum excepturi ea dolorem deserunt."
                                : inputs?.description}
                        </div>
                        <div className="Vbutton">
                            <button
                                style={{
                                    width: "140px",
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#006e7f",
                                    backgroundColor:
                                        buttonColor?.button ||
                                        inputs?.colorButton ||
                                        "#ffffff",
                                }}
                                onClick={() =>
                                    window.open(
                                        inputs?.button?.link ||
                                            "https://www.youtube.com/"
                                    )
                                }
                            >
                                {inputs?.button?.name || "Learn More"}
                            </button>
                        </div>
                        <div className="video mt-3">
                            {inputs?.videoURL?.slice(1).map((data) => {
                                return (
                                    <div className="utube-cards">
                                        {/* <iframe
                                            key={data}
                                            width="560"
                                            height="315"
                                            src={`https://www.youtube.com/embed/${data?.url?.slice(
                                                -11
                                            )}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        /> */}
                                          <div className="content" dangerouslySetInnerHTML={{__html: data?.iframe}}></div>
                                        <div className="u-tube-bottom">
                                            {data?.title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <ModalPopUp
                    ref={containerRef}
                    show={isShow}
                    onHide={() => setIsShow(false)}
                    buttonText="Learn More"
                    description="Name this optional button as you want and link to any website of your choice"
                />
            </div>
        </div>
    );
};
