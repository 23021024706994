import { forwardRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./modalComponent.css";
import { toast } from "react-toastify";
// import { publicRequest } from "../../requestMethod";
import logo1 from "../../asset/logo1.svg";
import qrscan from "./assets/qrscan.jpg";
import axios from "axios";
import ads_mobile from "../../asset/ads-modal-mobile.svg";
import donate from "../../asset/donate.svg";
import secure from "../../asset/secure.svg";
import countryList from "../../country.json";
import useLocation from "../../hooks/useLocation";

// function loadScript(src) {
//   return new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = () => {
//       resolve(true);
//     };
//     script.onerror = () => {
//       resolve(false);
//     };
//     document.body.appendChild(script);
//   });
// }
// const __DEV__ = document.domain === "localhost";
export const ModalComponent = (props) => {
  const { userLocation } = useLocation();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (userLocation?.country_name && !country) {
      setCountry(userLocation?.country_name);
    }
  }, [userLocation, country]);

  // useEffect(() => {
  //   const fetchPlan = async () => {
  //     try {
  //       const res = await publicRequest.get(`/plan/`);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchPlan();
  // }, []);
  const [amount, setAmount] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  async function donateRazorpay(planName, planType, price) {
    setLoading(true);
    if (price) {
      // const donationBody = {
      //     name: "Donation & Tips",
      //     price: price,
      //     country: country,
      // }
      const donationBody = {
        amount: price,
        currency: country === "India" ? "inr" : "usd",
        country: country,
      };
      const orderRes1 = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/payment/donate",
        donationBody
      );

      const { data } = orderRes1;
      window.location.replace(data.link);
      setLoading(false);
    } else {
      toast.error("amount cannot be empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  }

  const hnadleAmmount = (type, e) => {
    if (country === "")
      return toast.error("Please select your country first ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    setAmount(e);
  };

  useMemo(() => {
    setAmount("");
  }, [country]);

  return (
    <Modal
      {...props}
      size="lg"
      centered
      contentClassName="support-modal-content">
      <span class="modal-close-landing">
        <Modal.Header closeButton></Modal.Header>
      </span>
      <div className="qrcode-download-box">
        {/* Your QR Code is being generated. Please do not refresh or exit. */}
        Your QR Code is being downloaded, Please wait for download to complete
        and get your QR code ready
      </div>{" "}
      <Modal.Body className="support-modal-body">
        <div className="support-modal-first">
          <div>
            <img  loading="lazy"   alt="" src={donate} className="support-para-image" />
          </div>
          <div>
            <div className="support-modal-text-heading">
              Help us Grow and Make a Difference with Your Donations
            </div>
            <div className="support-modal-text">
              Introducing QRcodebrew.com, your go-to platform for seamless QR
              code generation! Support our mission to provide FREE, innovative
              features by contributing to our DONATE section. Your donations
              fuel our growth, enabling us to continually enhance our services
              for your benefit. Join the QRcodebrew community, and let's make QR
              magic together!
            </div>
          </div>
        </div>
        <div className="support-modal-second">
          <div className="heading">
            <img  loading="lazy"   alt="" src={secure} className="support-image" />
            <span>Secure Donation</span>
          </div>
          <h2 className="sub-heading-support">
            Designated to help us keeping the platform services free
          </h2>
          {isExpanded && (
            <div
              className="back"
              onClick={() => {
                setIsExpanded(false);
              }}>
              <i className="fas fa-long-arrow-alt-left"></i> Back
            </div>
          )}
          <div className="boxContainer">
            <>
              <select
                value={userLocation?.country_name}
                onChange={(e) => setCountry(e.target.value)}
                class="form-select">
                <option selected>Select Country</option>
                {countryList.map((country, index) => {
                  return (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  );
                })}
              </select>
              <div
                className={
                  (amount === "1" && country !== "India") ||
                    (amount === "80" && country === "India")
                    ? "box selected"
                    : "box"
                }
                onClick={() =>
                  hnadleAmmount("div", country === "India" ? "80" : "1")
                }>
                {country === "India" ? "₹80" : "$1"}
              </div>
              <div
                disabled={country === ""}
                title={country === "" ? "Please select your country" : ""}
                className={
                  (amount === "2" && country !== "India") ||
                    (amount === "160" && country === "India")
                    ? "box selected"
                    : "box"
                }
                onClick={() =>
                  hnadleAmmount("div", country === "India" ? "160" : "2")
                }>
                {country === "India" ? "₹160" : "$2"}
              </div>
              <div
                disabled={country === ""}
                title={country === "" ? "Please select your country" : ""}
                className={
                  (amount === "5" && country !== "India") ||
                    (amount === "400" && country === "India")
                    ? "box selected"
                    : "box"
                }
                onClick={() =>
                  hnadleAmmount("div", country === "India" ? "400" : "5")
                }>
                {country === "India" ? "₹400" : "$5"}
              </div>
              <div
                disabled={country === ""}
                style={{ position: "relative" }}
                title={country === "" ? "Please select your country" : ""}>
                <span
                  style={{
                    position: "absolute",
                    left: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}>
                  {country === "India" ? "₹" : "$"}
                </span>
                <input
                  type="number"
                  value={amount}
                  disabled={country === ""}
                  min={1}
                  className="box"
                  style={{
                    paddingLeft: "25px",
                    textAlign: "left",
                  }}
                  onChange={(e) => hnadleAmmount("input", e.target.value)}
                  placeholder="Add Amount"
                />
              </div>
            </>
          </div>
          <button
            disabled={country === ""}
            title={country === "" ? "Please select your country first" : ""}
            className="modalComponentButton"
            onClick={() => donateRazorpay("donate", "donate", amount)}>
            {loading ? "Downloading QR Code" : "Download QR Code"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const ModalDynamic = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      // id="modalDynamic-container"
      >
        <Modal.Header className="close-modal" closeButton>
          <h4 className="top-text text-center">{"The feature you are trying to use is a premium feature!"}    </h4>
        </Modal.Header>
        <Modal.Body className="modalComponentBody">
          <div className="">
              <h5 className="heading text-muted">
              To use premium features, buy {" "}
              <span
                style={{
                  color: "#db9d48",
                  fontSize: "18px",
                  fontWeight: "600",
                }}>
                premium subscription
              </span>
              {" "}with 15 days free trial.
            </h5>
            {/* <GiBottomRight3DArrow className="down-arrow-buy" /> */}
            {/* <img  loading="lazy"   alt="" src={arrow} /> */}
            <img  loading="lazy"  
              alt=""
              src={qrscan}
              style={{
                // height: 200,
                width: "70%",
                borderRadius: "3%",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Link to="/pricing" style={{ textDecoration: "none" }}>
                <div className="buy-button">
                  Buy Premium
                  <i className="fas fa-crown"></i>
                </div>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const ModalSignup = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      id="modalSignup-container"
      dialogClassName="modal-50w"
      centered>
      <div class="modal-body">
        <span class="modal-close">
          <Modal.Header closeButton></Modal.Header>
        </span>
        <div class="d-flex flex-row row my-5 mx-2">
          <div class="d-flex flex-column gap-4  p-3 col-lg-6 col-12">
            <div class="d-flex flex-column">
              <img  loading="lazy"   alt="logo" src={logo1} class="logo" />
              <h2 class="text-heading-1">{props.mainHeading}</h2>
              <span className="main-text-logo">
                {props.spanHeading}{" "}
                <span className="last-word">{props.spanHeadingLastWord}</span>
              </span>
              <div class="text-body">{props.mainBody}</div>
            </div>
            <div class="d-flex flex-column actions">
              <button class="register">
                <Link to="/signUp">FREE SIGN UP</Link>
              </button>
              {/* <span class="no-credit text-center">No credit card required</span> */}
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <img  loading="lazy"  
              alt=""
              class="w-100 h-100"
              src={props.ImageSrc}
              // loading="lazy"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalCookies = (props) => {
  const { show } = props;
  return (
    <div
      className="cookie-modal-body"
      style={{ display: show ? "block" : "none" }}>
      <div className="cookie-modal-body-cont">
        <div className="text">
          We care about your experience on our website, with your consent
          ("Accept All Cookies") we will use stored cookies to enhance your site
          navigation, analyze site usage, and assist our marketing efforts.
        </div>
        <div className="btn-cont">
          <div>
            <button
              className="accept-cookie"
              onClick={() => {
                props.onClose();
              }}>
              Accept All Cookies
            </button>
          </div>
          <div>
            <button
              className="reject-cookie"
              onClick={() => {
                props.onClose();
              }}>
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ModalAds = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      className="ads-modal-cont"
      contentClassName="ads-modal-content"
      centered>
      <Modal.Header className="ads-close-modal" closeButton></Modal.Header>
      <Modal.Body>
        <div className="ads-modal-body-cont">
          <div className="ads-modal-first">
            <div>
              <img  loading="lazy"   alt="" src={logo1} className="ads-modal-logo" />
            </div>
            <div className="signup-txt">Sign up to generate</div>
            <div className="color-theme-green">Dynamic QR Code</div>
            <div className="ads-modal-text">
              Sign up for free to use dynamic cards.
            </div>
            <div className="ads-modal-text">Create QR Cards Design & More.</div>
            <div>
              <Link to="/signUp">
                <button className="ads-modal-signupbtn">Sign up</button>
              </Link>
            </div>
            <div className="no-credit-text">No Credit Card Required</div>
          </div>
          <div className="ads-modal-second">
            <img  loading="lazy"   alt="" src={ads_mobile} />
          </div>
        </div>
        <div>
          <div>
            <button className="ads-modal-signupbtn md-show">
              <Link to="/signUp">Sign up</Link>
            </button>
          </div>
          <div className="no-credit-text md-show">No Credit Card Required</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const ModalPopUp = forwardRef((props, ref) => {
  return (
    <Modal
      {...props}
      size="sm"
      className="popup-modal-cont"
      contentClassName="popup-modal-content"
      centered
      container={ref}>
      <Modal.Body>
        <div className="popup-modal-body-cont">
          <div className="popup-modal-first">
            <div className="popup-btn m-4">
              {props.buttonText ? props.buttonText : "Grab Now"}
            </div>
          </div>
          <div className="popup-modal-second">{props.description}</div>

          <div className="popup-modal-third m-3" onClick={props.onHide}>
            {props.url ? (
              <a
                href={`https://${props.url}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
                rel="noreferrer">
                Got it
              </a>
            ) : (
              "Got it"
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export const BusinessPopUP = forwardRef((props, ref) => {
  return (
    <Modal
      {...props}
      size="sm"
      centered
      className="Business-modal-align pe-0"
      container={ref}>
      <Modal.Body>
        <div className="popup-modal-body-cont ">
          <div className="popup-modal-first bussiness-popup">
            {/* icons image source */}
            {props.icon && (
              <div>
                <img  loading="lazy"   src={props.icon} alt="noimg" />
              </div>
            )}
            {/* icon component */}
            {props?.iconComponent && <div>{props.iconComponent}</div>}
            <div style={{ marginTop: "1rem" }}>{props.title}</div>
          </div>
          <div className="popup-modal-second my-4">{props.description}</div>

          <div
            className="popup-modal-third mt-2"
            onClick={() => {
              props.onHide();
              props.callback && props.callback();
            }}>
            {props?.audiourl ? (
              <a
                href={props?.audiourl}
                className="text-decoration-none"
                style={{ textDecoration: "none" }}
                download>
                {props?.submitButtonText ? props?.submitButtonText : "Got it"}
              </a>
            ) : props?.submitButtonText ? (
              props?.submitButtonText
            ) : (
              "Got it"
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export const ContactPopupFeedback = forwardRef((props, ref) => {
  const [changeModal, setChange] = useState(false);
  const EmailBody = {
    email: props.email,
    type: "Feedback",
    name: props.input.name,
    userEmail: props.input.email,
    userNumber: props.input.no,
    feedback: [
      {
        category: props.title,
        review: props.rating,
        comment: props.input.review,
      },
    ],
  };
  function sendRating() {
    if (props.email) {
      if (changeModal) {
        props.onHide();
        setChange(false);
      } else {
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/send-email`,
          EmailBody
        );

        setChange(true);
      }
    } else {
      alert("Please input email to send rating");
    }
  }
  return (
    <Modal
      {...props}
      size="sm"
      className="popup-modal-cont"
      contentClassName="contact-modal-content"
      centered
      container={ref}>
      <Modal.Header className="contactpop-header">
        <Modal.Title className="contact-title">
          {changeModal
            ? "Thank you!!"
            : " Please enter your contact information if you want us to get back to you"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="">
          {changeModal ? (
            <div className="text-center mb-4">
              Your feedback has been submitted. <br />
              Thank you for taking the time
              <br /> to share your opinion.
            </div>
          ) : (
            <div>
              <input
                placeholder="Enter Your Name"
                className="contact-input"
                value={props.input.name}
                onChange={(e) =>
                  props.setInput({
                    ...props.input,
                    name: e.target.value,
                  })
                }
              />
              <input
                placeholder="Enter Your Email"
                className="contact-input"
                value={props.input.email}
                onChange={(e) =>
                  props.setInput({
                    ...props.input,
                    email: e.target.value,
                  })
                }
              />
              <input
                placeholder="Enter Your Number"
                className="contact-input"
                value={props.input.no}
                type="number"
                onChange={(e) =>
                  props.setInput({
                    ...props.input,
                    no: e.target.value,
                  })
                }
              />
            </div>
          )}
          <div className="" onClick={sendRating}>
            <button className="expanded-feedback-send-feedback">
              {changeModal ? "Back to Main Page" : "Send Now"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});
